import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Grid,
  useToast,
  Spinner,
  Button,
  useDisclosure,
  Switch,
  Flex,
} from '@chakra-ui/react'
import Resizer from 'react-image-file-resizer'
import { yupResolver } from '@hookform/resolvers/yup'
import axios, { AxiosResponse } from 'axios'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import * as yup from 'yup'
import DatePicker from 'react-date-picker'
import styled from '@emotion/styled'
import uuid from 'react-uuid'
import {
  AWS_BOOTH_IMG_PROCESSOR,
  BASE_CLOUDFRONT_URL,
  BASE_URL,
} from '../../../config'
import { StatusEnum } from '../../../interfaces'
import { uploadPhotoToS3 } from '../../../utils'
import {
  ThemedButton,
  ThemedDragDrop,
  ThemedFormErrorMessage,
  ThemedInput,
  ThemedLabel,
  ThemedModalTrashButton,
  ThemedSelect,
  ThemedTextarea,
} from '../../shared'
import { useAuthentication } from '../../../hooks'
import ThemedFormHelperMessage from '../../shared/ThemedFormHelperMessage'
import MapsAndBooths from './MapsAndBooths'
import { httpPost } from '../../../fetchers'

type CreateShowFormProps = {
  isOpen: boolean
  onClose: () => void
  mutate: () => Promise<AxiosResponse<any> | undefined>
  defaultValues?: Record<string, any>
}

interface ShowFormData {
  showName: string
  description: string
  endDate: Date
  family: string
  location: string
  producerEventCode: string
  startDate: Date
  status: { value: string; label: string }
  venue: string
  website: string
  images: File[]
  websiteSettings: {
    logo: {
      image?: File[]
      link: string
    }
    navigations: [
      {
        isChecked: boolean
        name: string
        link: string
      }
    ]
  }
  mapImages: File[]
}

const showSchema = yup.object().shape({
  images: yup.array().test({
    message: 'Please provide an image to upload.',
    test: arr => {
      if (arr?.length) {
        return arr?.length > 0
      }
      return false
    },
  }),
  websiteSettings: yup.object().shape({
    logo: yup.object().shape({
      image: yup.array().test({
        message: 'Please provide a logo to upload.',
        test: arr => {
          if (arr?.length) {
            return arr?.length > 0
          }
          return false
        },
      }),
      link: yup.string().test({
        message: 'Please provide a valid url.',
        test: arr => {
          if (arr?.length) {
            return !!arr?.match(
              /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
            )
          }
          return true
        },
      }),
    }),
    navigations: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        isChecked: yup.bool(),
        link: yup.string(),
      })
    ),
  }),
  showName: yup.string().required('Required.'),
  producerEventCode: yup.string().required('Required.'),
  family: yup.string().required('Required.'),
  description: yup.string().required('Required.'),
  location: yup.string().required('Required.'),
  venue: yup.string().required('Required.'),
  startDate: yup.string().required('Required.'),
  endDate: yup.string().required('Required.'),
  website: yup
    .string()
    .required('Required.')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Please provide a valid url.'
    ),
})

const options = {
  status: [
    {
      value: StatusEnum.PENDING,
      label: StatusEnum.PENDING,
    },
    {
      value: StatusEnum.ACTIVE,
      label: StatusEnum.ACTIVE,
    },
    {
      value: StatusEnum.CANCELLED,
      label: StatusEnum.CANCELLED,
    },
    {
      value: StatusEnum.CLOSED,
      label: StatusEnum.CLOSED,
    },
  ],
}

const navigations = [
  'Boats',
  'Brokerage Yachts',
  'Exhibitors',
  'Maps',
  'Schedule',
  'Discover Boating Resources',
  'Buy Tickets',
]

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 0.5rem 0.75rem;
  & > div {
    border: none;
  }
`

const CreateShowForm: React.FC<CreateShowFormProps> = ({
  isOpen,
  onClose,
  mutate,
  defaultValues = {},
}) => {
  const toast = useToast()
  const {
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    watch,
  } = useForm<ShowFormData>({
    defaultValues: {
      status: options.status[0],
      images: [],
      websiteSettings: {
        logo: {
          image: [],
        },
        navigations: [],
      },
    },
    resolver: yupResolver(showSchema),
  })
  const [hasImageChanged, setImageChanged] = useState(false)
  const [hasLogoChanged, setLogoChanged] = useState(false)

  const [isDeleting, setIsDeleting] = useState(false)
  const { isAuthenticated } = useAuthentication()
  const { isOpen: isOpenMaps, onOpen, onClose: onCloseMaps } = useDisclosure()
  const [maps, setMaps] = useState([])

  const defKeys = Object.keys(defaultValues)

  const onSubmit = async (data: ShowFormData) => {
    const forUpdate = defKeys.length

    const { status, startDate, endDate, images, websiteSettings, ...rest } =
      data

    // eslint-disable-next-line
    const navigations = websiteSettings?.navigations as { name: string, link: string, isChecked: boolean }[];

    // Check if websiteSettings and navigations array exist and has at least 6 elements
    if (navigations && navigations.length >= 6) {
        navigations[6].name = 'Buy Tickets';
        // eslint-disable-next-line
        console.log(navigations)
    } else {
        console.error('Invalid data structure or insufficient elements in the navigations array.');
    }

    if (new Date(startDate).valueOf() > new Date(endDate).valueOf()) {
      setError('startDate', {
        message: 'Start date should not exceed end date.',
      })
      return
    }
    if (new Date(startDate) < new Date() && !forUpdate) {
      setError('startDate', {
        message: 'Start date should not be behind current date.',
      })
      return
    }
    if (new Date(endDate) < new Date() && !forUpdate) {
      setError('endDate', {
        message: 'End date should not be behind current date.',
      })
      return
    }

    try {
      const image: File | null = images?.length ? images[0] : null
      const logoImage: File | null | undefined = websiteSettings.logo?.image
        ?.length
        ? websiteSettings?.logo?.image?.[0]
        : null

      const uploadNewImage = async (argImage = image) => {
        const fileValues = {
          fileName: argImage!.name,
          fileType: argImage!.type,
        }

        const {
          data: { url, fileNameHashed },
        } = await httpPost({
          url: `${BASE_URL}/getPresignedUrl`,
          body: fileValues,
        })

        await uploadPhotoToS3({ url, file: argImage! })
        return fileNameHashed
      }

      const deleteExistingImage = async (fileNameHashed: string) => {
        await httpPost({
          url: `${BASE_URL}/images/delete`,
          body: { fileNameHashed },
        })
      }

      const resizeFile = (file: File, imgDetails: any) =>
        new Promise(resolve => {
          Resizer.imageFileResizer(
            file,
            imgDetails.preferredWidth,
            imgDetails.preferredHeight,
            'PNG',
            100,
            0,
            uri => {
              resolve(uri)
            },
            'base64'
          )
        })

      const generateMapImage = async (argImg: File) => {
        const newImgDetails: any = await new Promise((res, rej) => {
          try {
            const img = new Image()
            const objectUrl = URL.createObjectURL(argImg)
            // eslint-disable-next-line
            img.onload = function () {
              let preferredWidth = img.width
              let preferredHeight = img.height
              let isResized = false

              if (img.width >= 2000) {
                const aspectRatio = img.height / img.width
                preferredWidth = 2000
                preferredHeight = aspectRatio * preferredWidth
                isResized = true
              }

              URL.revokeObjectURL(objectUrl)
              res({ preferredWidth, preferredHeight, isResized })
            }
            img.src = objectUrl
          } catch (err) {
            rej(err)
          }
        })

        if (!newImgDetails?.isResized) return argImg

        const newMapImage = (await resizeFile(argImg, newImgDetails)) as string

        const imageRes = await axios.get(newMapImage, {
          responseType: 'blob',
        })

        const id = uuid()

        const file = new File(
          [imageRes.data],
          `${id.slice(0, 15)}.png`,
          imageRes.data
        )

        Object.defineProperty(file, 'fileName', {
          value: `${id.slice(0, 15)}.png`,
          writable: false,
        })

        return file
      }

      const formattedMaps = await Promise.all(
        maps.map(async (map: any) => {
          let mapFileNameHashed = map?.mapFileNameHashed
          const mapFileName = map?.image ? map.image.name : map.mapFileName

          if (map.image && !map?.previousImage && !mapFileNameHashed) {
            const mapImage = await generateMapImage(map.image)
            mapFileNameHashed = await uploadNewImage(mapImage)
          }
          if (map.image && mapFileNameHashed && map?.previousImage) {
            await deleteExistingImage(mapFileNameHashed)
            const mapImage = await generateMapImage(map.image)
            mapFileNameHashed = await uploadNewImage(mapImage)
          }

          const booths = await Promise.all(
            map?.booths?.map(async (b: any) => {
              let boothFileNameHashed = b?.boothFileNameHashed
              const hasCoordinatesChanged = false || b.hasCoordinatesChanged
              const hasNewMap =
                (map.image && !map?.previousImage && !mapFileNameHashed) ||
                (map.image && mapFileNameHashed && map?.previousImage)
              const id = b.id ? b.id : uuid()

              if (
                b?.coordinates?.x > 0 &&
                (hasNewMap || hasCoordinatesChanged)
              ) {
                const res = await axios.post(AWS_BOOTH_IMG_PROCESSOR, {
                  src: `${BASE_CLOUDFRONT_URL}/${mapFileNameHashed}`,
                  boothCoordinates: b.coordinates,
                })

                if (res?.data?.imgSrc) {
                  const imageRes = await axios.get(res?.data.imgSrc, {
                    responseType: 'blob',
                  })

                  const file = new File(
                    [imageRes.data],
                    `${id.slice(0, 15)}.png`,
                    imageRes.data
                  )

                  Object.defineProperty(file, 'fileName', {
                    value: `${id.slice(0, 15)}.png`,
                    writable: false,
                  })

                  if (boothFileNameHashed) {
                    await deleteExistingImage(boothFileNameHashed)
                  }

                  boothFileNameHashed = await uploadNewImage(file)
                }
              }
              return {
                ...b,
                id,
                boothFileNameHashed,
                hasCoordinatesChanged: false,
              }
            })
          )

          const newMap = {
            ...map,
            mapFileName,
            mapFileNameHashed,
            booths,
          }

          delete newMap.previousImage
          delete newMap.image

          return newMap
        })
      )

      let fileNameHash = ''
      let logoFileNameHash = ''

      if (image && !defaultValues?.fileNameHashed) {
        fileNameHash = await uploadNewImage()
      }
      if (image && defaultValues?.fileNameHashed && hasImageChanged) {
        await deleteExistingImage(defaultValues?.fileNameHashed)
        fileNameHash = await uploadNewImage()
      }

      if (logoImage && !defaultValues?.websiteSettings?.logo?.fileNameHashed) {
        logoFileNameHash = await uploadNewImage(logoImage)
      }
      if (
        logoImage &&
        defaultValues?.websiteSettings?.logo?.fileNameHashed &&
        hasLogoChanged
      ) {
        await deleteExistingImage(
          defaultValues?.websiteSettings?.logo?.fileNameHashed
        )
        logoFileNameHash = await uploadNewImage(logoImage)
      }

      const values = {
        ...rest,
        ...(image !== null
          ? {
              fileName: hasImageChanged ? image?.name : defaultValues?.fileName,
              fileNameHashed: hasImageChanged
                ? fileNameHash
                : defaultValues?.fileNameHashed,
            }
          : {
              fileNameHashed: '',
              fileName: '',
            }),
        websiteSettings: {
          navigations: websiteSettings.navigations,
          logo: {
            ...(logoImage !== null
              ? {
                  fileName: hasLogoChanged
                    ? logoImage?.name
                    : defaultValues?.websiteSettings.logo.fileName,
                  fileNameHashed: hasLogoChanged
                    ? logoFileNameHash
                    : defaultValues?.websiteSettings.logo.fileNameHashed,
                  link: websiteSettings.logo.link,
                }
              : {
                  fileNameHashed: '',
                  fileName: '',
                  link: websiteSettings.logo.link,
                }),
          },
        },
        maps: formattedMaps,
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
        status: status.value,
        ...(forUpdate && { showId: defaultValues.showId }),
      }

      const res = await httpPost({
        url: `${BASE_URL}/show/${forUpdate ? 'update' : 'create'}`,
        body: values,
      })

      if (res?.data) {
        mutate().then(() => {
          toast({
            title: `Show ${forUpdate ? 'updated' : 'created'}.`,
            description: `The show has been ${
              forUpdate ? 'updated' : 'created'
            }.`,
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
        })
        onClose()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onDelete = async () => {
    setIsDeleting(true)
    if (defaultValues?.showId) {
      try {
        const res = await httpPost({
          url: `${BASE_URL}/show/delete`,
          body: {
            showId: defaultValues.showId,
          },
        })

        if (res?.data) {
          mutate?.().then(() => {
            toast({
              title: 'Show has been deleted.',
              description: 'The show has been deleted.',
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
            onClose()
          })
        }
      } catch (err) {
        const errResponse = (err as any)?.response
        toast({
          title: 'Failed to delete a show.',
          description: errResponse.data.message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
        setIsDeleting(false)
      }
    }
  }

  const startDateField = register('startDate')
  const endDateField = register('endDate')

  useDeepCompareEffect(() => {
    if (defKeys.length) {
      defKeys.forEach(key => {
        setValue(key as keyof ShowFormData, defaultValues[key])
      })
      setMaps(defaultValues?.maps ?? [])
    } else {
      reset()
    }
  }, [defaultValues])

  const imagesField = register('images')
  const logoField = register('websiteSettings.logo.image')

  const watchedImages = watch('images')
  const watchedLogos = watch('websiteSettings.logo.image')

  useDeepCompareEffect(() => {
    if (watchedImages?.length && errors?.images?.message) {
      clearErrors('images')
    }
  }, [watchedImages, errors])

  useDeepCompareEffect(() => {
    if (watchedLogos?.length && errors?.websiteSettings?.logo?.image?.message) {
      clearErrors('websiteSettings.logo.image')
    }
  }, [watchedLogos, errors])

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="0px" minW="700px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center" pt="50px">
            <Text as="span" fontWeight="600" fontSize="32px">
              Shows
            </Text>
          </ModalHeader>

          {!!defKeys.length && isAuthenticated && (
            <ThemedModalTrashButton
              onClick={onDelete}
              isDeleting={isDeleting}
              entity="show"
              message={
                <Text as="span">
                  Deleting this show will affect <Text as="strong">all</Text>{' '}
                  exhibitors. Please make sure that associated exhibitors have
                  been referred to a new show.
                </Text>
              }
              customWidth="600px"
            />
          )}
          <ModalCloseButton
            color="mediumGray"
            marginTop="15px"
            marginRight="10px"
            isDisabled={isSubmitting || isDeleting}
          />
          <ModalBody paddingLeft="32px" paddingRight="32px">
            <Grid
              mt="20px"
              templateColumns="repeat(2,1fr)"
              autoColumns="max-content"
              autoRows="auto"
              rowGap="18px"
              columnGap="16px"
            >
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="images">Upload Image</ThemedLabel>
                <ThemedDragDrop
                  id="images"
                  {...imagesField}
                  ref={imagesField.ref}
                  setValue={setValue}
                  fileNameHashed={defaultValues?.fileNameHashed}
                  fileName={defaultValues.fileName}
                  hasImageChanged={hasImageChanged}
                  setImageChanged={setImageChanged}
                  isSingleUpload
                />
                <ThemedFormErrorMessage>
                  {errors.images?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="websiteSettings.logo.image">
                  Upload Logo
                </ThemedLabel>
                <ThemedDragDrop
                  id="websiteSettings.logo.image"
                  {...logoField}
                  ref={logoField.ref}
                  setValue={setValue}
                  fileNameHashed={
                    defaultValues?.websiteSettings?.logo?.fileNameHashed
                  }
                  fileName={defaultValues?.websiteSettings?.logo?.fileName}
                  hasImageChanged={hasLogoChanged}
                  setImageChanged={setLogoChanged}
                  isSingleUpload
                />
                <ThemedFormErrorMessage>
                  {errors.websiteSettings?.logo?.image?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="websiteSettings.logo.link">
                  Logo Redirect Link
                </ThemedLabel>
                <ThemedInput
                  id="websiteSettings.logo.link"
                  {...register('websiteSettings.logo.link')}
                  defaultValue={defaultValues?.websiteSettings?.logo?.link}
                />
                <ThemedFormErrorMessage>
                  {errors.websiteSettings?.logo?.link?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel mb="16px">Navigations</ThemedLabel>
                {navigations.map((n, i) => (
                  <Flex key={n} w="full" alignItems="center" mb="10px">
                    <Flex
                      alignItems="center"
                      w={i !== navigations.length - 1 && i !== navigations.length - 2 ? '278px' : '500px'}
                    >
                      <Switch
                        {...(!defKeys?.includes('websiteSettings') && {
                          defaultChecked: true,
                        })}
                        {...(i === 0 && {
                          isChecked: true,
                        })}
                        {...register(
                          //  @ts-ignore
                          `websiteSettings.navigations.${i}.isChecked`
                        )}
                      />
                      <Text
                        as="span"
                        display="block"
                        mt="-2px"
                        ml="10px"
                        fontSize="sm"
                      >
                        {n}
                      </Text>
                    </Flex>

                    <input
                      type="hidden"
                      height="40px"
                      {...register(
                        //  @ts-ignore
                        `websiteSettings.navigations.${i}.name`
                      )}
                      value={n}
                    />
                    {i !== navigations.length - 1 && i !== navigations.length - 2 ? (
                      <Text as="span" fontSize="sm">
                        {`/${n.toLowerCase().split(' ').join('-')}`}{' '}
                      </Text>
                    ) : (
                      <></>
                    )}
                    <ThemedInput
                      height="40px"
                      {...register(
                        //  @ts-ignore
                        `websiteSettings.navigations.${i}.link`
                      )}
                      {...(i !== navigations.length - 1 && i !== navigations.length - 2
                        ? {
                            value: `/${n.toLowerCase().split(' ').join('-')}`,
                            display: 'none',
                          }
                        : { display: 'block' })}
                    />
                  </Flex>
                ))}
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel>Maps &amp; Booths</ThemedLabel>
                <Button
                  w="full"
                  size="lg"
                  fontSize="sm"
                  color="darkGray"
                  fontWeight="normal"
                  onClick={() => {
                    onOpen()
                  }}
                >
                  View maps and booths
                </Button>
                <MapsAndBooths
                  isOpen={isOpenMaps}
                  onClose={onCloseMaps}
                  maps={maps}
                  setMaps={setMaps}
                />
                <ThemedFormHelperMessage mt="12px">
                  You have {maps?.length || 0} maps and{' '}
                  {maps?.reduce(
                    (acc, curr: any) =>
                      acc + curr?.booths?.filter((b: any) => b.name)?.length,
                    0
                  ) || 0}{' '}
                  booths.
                </ThemedFormHelperMessage>
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="showName">Show Name</ThemedLabel>
                <ThemedInput id="showName" {...register('showName')} />
                <ThemedFormErrorMessage>
                  {errors.showName?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="producerEventCode">
                  Producer Event Code
                </ThemedLabel>
                <ThemedInput
                  id="producerEventCode"
                  {...register('producerEventCode')}
                />
                <ThemedFormErrorMessage>
                  {errors.producerEventCode?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="family">Event Family</ThemedLabel>
                <ThemedInput id="family" {...register('family')} />
                <ThemedFormErrorMessage>
                  {errors.family?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box gridColumn="1 / 3">
                <ThemedLabel htmlFor="description">Description</ThemedLabel>
                <ThemedTextarea id="description" {...register('description')} />
                <ThemedFormErrorMessage>
                  {errors.description?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="location">Location</ThemedLabel>
                <ThemedInput id="location" {...register('location')} />
                <ThemedFormErrorMessage>
                  {errors.location?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="venue">Venue</ThemedLabel>
                <ThemedInput id="venue" {...register('venue')} />
                <ThemedFormErrorMessage>
                  {errors.venue?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="startDate">Start Date</ThemedLabel>
                <Box
                  border="1px solid"
                  borderColor="mediumGray"
                  borderRadius="10px"
                >
                  <StyledDatePicker
                    {...startDateField}
                    onChange={(val: Date) => {
                      setValue('startDate', val)

                      if (errors.startDate?.message !== 'Required.') {
                        clearErrors('startDate')
                        clearErrors('endDate')
                      }
                    }}
                    value={watch('startDate')}
                    defaultValue={defaultValues.startDate}
                  />
                </Box>
                <ThemedFormErrorMessage>
                  {errors.startDate?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="endDate">End Date</ThemedLabel>
                <Box
                  border="1px solid"
                  borderColor="mediumGray"
                  borderRadius="10px"
                >
                  <StyledDatePicker
                    {...endDateField}
                    onChange={(val: Date) => {
                      setValue('endDate', val)

                      if (errors.startDate?.message !== 'Required.') {
                        clearErrors('startDate')
                        clearErrors('endDate')
                      }
                    }}
                    value={watch('endDate')}
                    defaultValue={defaultValues.endDate}
                  />
                </Box>
                <ThemedFormErrorMessage>
                  {errors.endDate?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box>
                <ThemedLabel htmlFor="status">Status</ThemedLabel>
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="status"
                      options={options.status}
                      isSearchable={false}
                      defaultValue={defaultValues?.status ?? options.status[0]}
                      maxWidthOptions={299}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box>
                <ThemedLabel htmlFor="website">Website</ThemedLabel>
                <ThemedInput id="website" {...register('website')} />
                <ThemedFormErrorMessage>
                  {errors.website?.message}
                </ThemedFormErrorMessage>
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter justifyContent="center" pt="46px" pb="48px">
            <ThemedButton
              isDisabled={isSubmitting || isDeleting}
              type="submit"
              height="55px"
              width="158px"
            >
              {!!defKeys.length && isSubmitting && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              )}
              {!!defKeys.length && !isSubmitting && (
                <Text as="span">Update</Text>
              )}
              {!defKeys.length && isSubmitting && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              )}
              {!defKeys.length && !isSubmitting && (
                <Text as="span">Submit</Text>
              )}
            </ThemedButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateShowForm
