import { Box } from '@chakra-ui/react'
import React from 'react'

import { BASE_CLOUDFRONT_URL } from '../../config'
import { useCompany } from '../../hooks'

const CompanyImage: React.FC = () => {
  const { companyRecord } = useCompany()

  return (
    <Box
      height="40px"
      width="40px"
      {...(companyRecord?.record?.fileNameHashed
        ? {
            background: `url(${BASE_CLOUDFRONT_URL}/${companyRecord?.record?.fileNameHashed}) no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
          }
        : { backgroundColor: 'gray6' })}
      mt="6px"
      borderRadius="10px"
      mr="28px"
    />
  )
}

export default CompanyImage
