import {
  Flex,
  Box,
  Text,
  useDisclosure,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  FlexProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useAtom } from 'jotai'
import { debounce, isEqual } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FiPlus, FiSearch, FiX } from 'react-icons/fi'
import uuid from 'react-uuid'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { companyRecordAtom } from '../../atoms'
import { CreateExhibitorBoatProductsForm } from '../../components/partials'
import {
  ThemedButton,
  ThemedSelect,
  ThemedTable,
} from '../../components/shared'
import { fetcher } from '../../fetchers'
import { useAuthentication, useExhibitors } from '../../hooks'
import {
  AWSQueryOutput,
  // CompanyTypeEnum,
  ExhibitorBoatProduct,
  QueryVariables,
  StatusEnum,
  ThemedSelectItem,
} from '../../interfaces'
import ThemedFormHelperMessage from '../../components/shared/ThemedFormHelperMessage'
import useShows from '../../hooks/useShows'

const filters = {
  status: [
    { value: 'All', label: 'All' },
    { value: StatusEnum.APPROVED, label: StatusEnum.APPROVED },
    { value: StatusEnum.PENDING, label: StatusEnum.PENDING },
    { value: StatusEnum.REJECTED, label: StatusEnum.REJECTED },
  ],
}

const StyledThemedTable = styled(ThemedTable)`
  & {
    width: ${({ isLoading }) =>
      isLoading ? 'calc(100%)' : 'calc(100% - 200px)'};

    @media (min-width: 1500px) {
      width: calc(100%);
    }
  }
`

const StyledFlex: React.FC<FlexProps & { isFooter?: boolean }> = ({
  children,
  ...props
}) => <Flex {...props}>{children}</Flex>

const StyledEBPFlex = styled(StyledFlex)`
  & {
    width: ${({ isFooter }) =>
      isFooter
        ? 'calc(calc(100vw - 245px) - 292px)'
        : 'calc(calc(100vw - 245px) - 302px)'};

    @media (min-width: 1500px) {
      width: ${({ isFooter }) =>
        isFooter
          ? 'calc(calc(100vw - 245px) - 292px)'
          : 'calc(calc(100vw - 245px) - 322px)'};
    }
  }
`

const defaultColumns = [
  'Exhibitor',
  'Show',
  'Booth',
  'Show Year',
  'Approval Status',
  'Brand',
  'Boat Product',
  'Model Year',
  'Exhibitor ID',
]

const ExhibitorBoatProducts: React.FC = () => {
  const { isAuthenticated } = useAuthentication()
  const [companyRecord] = useAtom(companyRecordAtom)
  const { exhibitors } = useExhibitors()
  const ref = useRef<HTMLInputElement>(null)
  const [boatProductKeyword, setBoatProductKeyword] = useState('')

  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})
  const location = useLocation()
  const locationState = location?.state as any
  const { register, setValue } = useForm()
  const [statusKey, setStatusKey] = useState('')
  const [filter, setFilter] = useState<Record<string, any>>({})
  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 10,
    savedCursor: [null],
    currentPage: 1,
  })

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    [
      `/company/${companyRecord.record?.companyId.replaceAll(
        '/',
        '%2f'
      )}/exhibitor-boat-products?currentPage=${variables.currentPage}&first=${
        variables.first
      }${
        boatProductKeyword
          ? `&boatProductKeyword=${encodeURIComponent(boatProductKeyword)}`
          : ''
      }`,
      filter,
    ],
    fetcher
  )

  const isLoading = !error && !res

  const records = (res?.data as AWSQueryOutput)?.Items?.map(i => {
    const c = i as ExhibitorBoatProduct

    return {
      ...c,
      id: c.exhibitorBoatProductId,
      exhibitorId: c?.exhibitor?.exhibitorId,
      exhibitor: c?.exhibitor?.bannerName,
      show: c?.show?.showName,
      boatProduct: c.boatProduct?.productName,
      boatProductId: c.boatProduct?.boatProductId,
      showCode: c?.show?.producerEventCode,
      brand: c?.brandName,
      booth: c.mapsBooths?.reduce((acc: string, curr: any, i: number) => {
        const [, mapId, boothId] = curr.value.split('::')
        const map = c?.show?.maps?.find((m: any) => m.id === mapId) || []
        const booth = map?.booths?.find((b: any) => b.id === boothId)

        return `${acc}${booth?.name ? `${map.name} - ${booth.name}` : ''}${
          i < (c.mapsBooths?.length || 0) - 1 ? ',' : ''
        } `
      }, ''),
      approvalStatus: c.status,
    }
  })

  const { shows } = useShows()

  // useEffect(() => {
  //   if (companyRecord.record?.type) {
  //     setColumns(
  //       companyRecord.record.type === CompanyTypeEnum.BRAND
  //         ? defaultColumns
  //         : [
  //             ...defaultColumns.slice(0, 4),
  //             'Brand',
  //             ...defaultColumns.slice(-2),
  //           ]
  //     )
  //   }
  // }, [companyRecord.record?.type])

  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setDefaultValues({})
  }

  const onRowClick = (row: any) => {
    const record = (res?.data as AWSQueryOutput)?.Items?.find(
      c =>
        c.exhibitorBoatProductId === row.id &&
        c.boatProduct.boatProductId === row.boatProductId
    )

    setDefaultValues({
      ...record,
      exhibitor: {
        label: `${record?.exhibitor?.bannerName ?? ''}${
          record?.show.showName ? ` (${record?.show.showName})` : ''
        }`,
        value: `${record?.exhibitor?.exhibitorId}~${record?.show?.showId}`,
      },
      status: { label: record?.status, value: record?.status },
      boatProduct: [
        {
          label: record?.boatProduct.productName,
          value: record?.boatProduct.boatProductId,
          companyId: record?.boatProduct.companyId,
        },
      ],
      ...(record?.brands?.length && {
        brandIds: record.brands.map(({ name, companyId }: any) => ({
          label: name,
          value: companyId,
        })),
      }),
      // ...(companyRecord.record?.type === CompanyTypeEnum.BRAND && {
      //   brandIds: [
      //     {
      //       label: companyRecord.record.name,
      //       value: companyRecord.record.companyId,
      //     },
      //   ],
      // }),
      mapsBooths: record?.mapsBooths?.map((b: any) => {
        const [, mapId, boothId] = b.value.split('::')
        const map = record?.show?.maps?.find((m: any) => m.id === mapId) || []
        const booth = map?.booths?.find((b: any) => b.id === boothId)

        if (b.value === '-') {
          return {
            label: 'No Booth - Not at Show',
            value: '-',
          }
        }

        return {
          ...b,
          label: booth?.name ? `${map.name} - ${booth.name}` : '',
        }
      }),
    })
    onOpen()
  }

  const onSort = (name: string) => {
    if (
      name === 'Exhibitor' ||
      name === 'Show' ||
      name === 'Approval Status' ||
      name === 'Boat Product' ||
      name === 'Brand'
    ) {
      setVariables(v => ({
        ...v,
        currentPage: 1,
      }))

      setFilter({
        ...filter,
        sortBy: name,
        sortOrder:
          filter.sortOrder &&
          filter.sortOrder === 'asc' &&
          filter.sortBy === name
            ? 'desc'
            : 'asc',
      })
    }

    if (name === 'Show Year' || name === 'Model Year') {
      setVariables(v => ({
        ...v,
        currentPage: 1,
      }))

      setFilter({
        ...filter,
        sortBy: name,
        sortOrder:
          filter.sortOrder &&
          filter.sortOrder === 'desc' &&
          filter.sortBy === name
            ? 'asc'
            : 'desc',
      })
    }
  }

  const handleNext = () => {
    setVariables(v => ({
      ...v,
      currentPage: v.currentPage! + 1,
    }))
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      currentPage: v.currentPage! - 1,
    }))
  }

  const handleSetQueryPageSize = (arg: number) => {
    setVariables({ ...variables, first: arg, currentPage: 1 })
  }

  const changeHandler = (event: any) => {
    setBoatProductKeyword(event.target.value)
    setVariables(v => ({
      ...v,
      currentPage: 1,
      savedCursor: [null],
    }))
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [])

  useEffect(() => {
    if (locationState?.pending) {
      setValue('status', filters.status[2])
      setFilter({ status: StatusEnum.PENDING })
      setStatusKey(uuid())
    }
  }, [locationState])

  const statusField = register('status')

  return (
    <Box pb="171px" position="relative">
      <StyledEBPFlex justifyContent="space-between" mt="54px" ml="51px">
        <Box>
          <Text as="span" fontSize="32px" fontWeight="600">
            My Boats
          </Text>
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            maxW={isAuthenticated ? '60ch' : '80ch'}
          >
            {isAuthenticated
              ? 'Please assign the boats that you will be bringing to the show. Click on the + Create button to start assigning Boats to Exhibit spaces'
              : 'When at least one exhibitor assigns themselves to a boat model your manufacturer, your entire fleet of boats will be available on the default view of the SRP.'}
          </Text>
        </Box>
        <Box position="relative">
          {!exhibitors.length && (
            <Box
              position="absolute"
              right="0px"
              height="55px"
              width="158px"
              zIndex="2"
            >
              <Popover trigger="hover" flip>
                <PopoverTrigger>
                  <Box
                    position="relative"
                    right="0px"
                    height="55px"
                    width="158px"
                    cursor="not-allowed"
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverHeader fontWeight="semibold">
                    No exhibitors found.
                  </PopoverHeader>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text as="span" fontSize="sm">
                      You have to create first an exhibitor before you can
                      proceed in creating an exhibitor boat product.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          )}
          <ThemedButton
            type="button"
            height="55px"
            width="158px"
            onClick={onOpen}
            leftIcon={<FiPlus color="white" fontSize="22px" />}
            isDisabled={isLoading || !exhibitors.length}
          >
            <Text as="span" ml="8px">
              Create
            </Text>
          </ThemedButton>
        </Box>
      </StyledEBPFlex>
      <Flex ml="51px" mt="47px" alignItems="center">
        <Box w="300px" mr="1rem">
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            mb="4px"
          >
            Boat Product
          </Text>
          <InputGroup size="md" maxW="sm">
            <InputLeftElement
              pointerEvents="none"
              color="gray.500"
              mt="4px"
              ml="3px"
            >
              <FiSearch />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search"
              backgroundColor="white"
              onChange={debouncedChangeHandler}
              ref={ref}
              h="50px"
            />
            {boatProductKeyword && (
              <InputRightElement
                color="gray.500"
                cursor="pointer"
                mt="4px"
                mr="3px"
                onClick={() => {
                  setBoatProductKeyword('')
                  ref.current!.value = ''
                  setVariables(v => ({
                    ...v,
                    savedCursor: [null],
                    currentPage: 1,
                  }))
                }}
              >
                <FiX />
              </InputRightElement>
            )}
          </InputGroup>
        </Box>
        <Box w="200px">
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            mb="4px"
          >
            Show
          </Text>
          <ThemedSelect
            id=""
            options={shows}
            maxWidthOptions={230}
            hasShadow
            color="#718096"
            {...statusField}
            key={statusKey}
            isSearchable
            isClearable
            onChange={(e: ThemedSelectItem) => {
              const value = e?.value ?? null

              if (!isEqual(filter, { ...filter, showId: value })) {
                setVariables(v => ({
                  ...v,
                  savedCursor: [null],
                  currentPage: 1,
                }))
                if (value === null) {
                  setFilter(prev => {
                    const newPrev = prev
                    delete newPrev.showId
                    return {
                      ...newPrev,
                    }
                  })
                } else {
                  setFilter(prev => ({
                    ...prev,
                    showId: value,
                  }))
                }
              }
            }}
          />
        </Box>
      </Flex>
      <StyledThemedTable
        columns={defaultColumns}
        rows={records}
        mt="24px"
        isLoading={isLoading}
        onRowClick={onRowClick}
        disableNext={
          Number(variables.currentPage) * Number(variables.first || 0) >
          res?.data?.TotalItems
        }
        disablePrev={Number(variables.currentPage) === 1}
        handleNext={handleNext}
        handlePrev={handlePrev}
        onSort={onSort}
        setQueryPageSize={handleSetQueryPageSize}
      />

      <StyledEBPFlex
        justifyContent="end"
        ml="51px"
        mb="20px"
        bottom="0px"
        isFooter
      >
        <ThemedFormHelperMessage>
          For questions, please contact showservice@nmma.org
        </ThemedFormHelperMessage>
      </StyledEBPFlex>
      <CreateExhibitorBoatProductsForm
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
        companyId={companyRecord.record?.companyId}
        defaultValues={defaultValues}
      />
    </Box>
  )
}

export default ExhibitorBoatProducts
