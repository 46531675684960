import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Grid,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import * as yup from 'yup'

import { BASE_URL } from '../../../config'
import { httpPost } from '../../../fetchers'

import {
  ThemedButton,
  ThemedFormErrorMessage,
  ThemedInput,
  ThemedLabel,
  ThemedModalTrashButton,
} from '../../shared'

type CreateContactFormProps = {
  isOpen: boolean
  onClose: () => void
  mutate?: () => Promise<AxiosResponse<any> | undefined>
  companyId?: string | undefined
  defaultValues?: Record<string, any>
  isAllowedToUpdate?: boolean
}

interface FormData {
  companyId: string
  firstName: string
  lastName: string
  prefix: string
  address: string
  email: string
  phone: string
  facebook: string
  linkedin: string
  youtube: string
  twitter: string
  title: string
}

const contactSchema = yup.object().shape({
  firstName: yup.string().required('Required.'),
  lastName: yup.string().required('Required.'),
  prefix: yup.string(),
  address: yup.string(),
  email: yup
    .string()
    .required('Required.')
    .email('Please provide a valid email.'),
  phone: yup.string().required('Required.'),
  facebook: yup
    .string()
    .test(
      'is-valid-url',
      'Please provide a valid url.',
      value =>
        !value ||
        !!value?.match(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
        )
    ),
  linkedin: yup
    .string()
    .test(
      'is-valid-url',
      'Please provide a valid url.',
      value =>
        !value ||
        !!value?.match(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
        )
    ),
  youtube: yup.string(),
  twitter: yup
    .string()
    .test(
      'is-valid-url',
      'Please provide a valid url.',
      value =>
        !value ||
        !!value?.match(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
        )
    ),
  title: yup.string(),
})

const CreateContactForm: React.FC<CreateContactFormProps> = ({
  isOpen,
  onClose,
  companyId,
  mutate,
  defaultValues = {},
  isAllowedToUpdate = true,
}) => {
  const toast = useToast()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      companyId,
    },
    resolver: yupResolver(contactSchema),
  })

  const defKeys = Object.keys(defaultValues)
  const [isDeleting, setIsDeleting] = useState(false)

  const onSubmit = async (data: FormData) => {
    const forUpdate = defKeys.length

    const values = {
      ...data,
      companyId,
      ...(forUpdate && { contactId: defaultValues.contactId }),
    }

    try {
      const res = await httpPost({
        url: `${BASE_URL}/contact/${forUpdate ? 'update' : 'create'}`,
        body: values,
      })

      if (res?.data) {
        mutate?.().then(() => {
          toast({
            title: `Contact ${forUpdate ? 'updated' : 'created'}.`,
            description: `The contact has been ${
              forUpdate ? 'updated' : 'created'
            }.`,
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
        })
        onClose()
      }
    } catch (err) {
      const errResponse = (err as any)?.response

      if (errResponse) {
        const { code } = errResponse.data

        if (code === 'ConditionalCheckFailedException') {
          toast({
            title: `Failed to ${forUpdate ? 'update' : 'create'} a contact.`,
            description:
              'There is an existing contact with the same name and email.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: `Failed to ${forUpdate ? 'update' : 'create'} a contact.`,
            description: errResponse.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      } else {
        console.error(err)
      }
    }
  }

  const onDelete = async () => {
    setIsDeleting(true)
    if (defaultValues?.contactId) {
      try {
        const res = await httpPost({
          url: `${BASE_URL}/contact/delete`,
          body: {
            companyId,
            contactId: defaultValues?.contactId,
          },
        })

        if (res?.data) {
          mutate?.().then(() => {
            toast({
              title: 'Contact has been deleted.',
              description: 'The contact has been deleted.',
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
          })
          onClose()
        }
      } catch (err) {
        const errResponse = (err as any)?.response

        toast({
          title: 'Failed to delete a contact.',
          description: errResponse.data.message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
        setIsDeleting(false)
      }
    }
  }

  useDeepCompareEffect(() => {
    if (defKeys.length) {
      defKeys.forEach(key => {
        setValue(key as keyof FormData, defaultValues[key])
      })
    } else {
      reset()
    }
  }, [defaultValues])

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="0px" minW="700px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center" pt="50px">
            <Text as="span" fontWeight="600" fontSize="32px">
              Contact
            </Text>
            {!!defKeys.length && (
              <ThemedModalTrashButton
                onClick={onDelete}
                isDeleting={isDeleting}
                entity="contact"
              />
            )}
          </ModalHeader>
          <ModalCloseButton
            color="mediumGray"
            marginTop="15px"
            marginRight="10px"
            isDisabled={isSubmitting || isDeleting}
          />
          <ModalBody paddingLeft="32px" paddingRight="32px">
            <Grid
              mt="20px"
              templateColumns="repeat(2, 1fr)"
              autoColumns="max-content"
              autoRows="auto"
              rowGap="18px"
              columnGap="16px"
            >
              <Box>
                <ThemedLabel htmlFor="firstName">First Name</ThemedLabel>
                <ThemedInput id="firstName" {...register('firstName')} />
                <ThemedFormErrorMessage>
                  {errors.firstName?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="lastName">Last Name</ThemedLabel>
                <ThemedInput id="lastName" {...register('lastName')} />
                <ThemedFormErrorMessage>
                  {errors.lastName?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="prefix">Prefix</ThemedLabel>
                <ThemedInput id="prefix" {...register('prefix')} />
                <ThemedFormErrorMessage>
                  {errors.prefix?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="address">Address</ThemedLabel>
                <ThemedInput id="address" {...register('address')} />
                <ThemedFormErrorMessage>
                  {errors.address?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="email">Email</ThemedLabel>
                <ThemedInput id="email" {...register('email')} />
                <ThemedFormErrorMessage>
                  {errors.email?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="phone">Phone</ThemedLabel>
                <ThemedInput id="phone" {...register('phone')} />
                <ThemedFormErrorMessage>
                  {errors.phone?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="facebook">Facebook</ThemedLabel>
                <ThemedInput id="facebook" {...register('facebook')} />
                <ThemedFormErrorMessage>
                  {errors.facebook?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="linkedin">Linkedin</ThemedLabel>
                <ThemedInput id="linkedin" {...register('linkedin')} />
                <ThemedFormErrorMessage>
                  {errors.linkedin?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="youtube">Youtube</ThemedLabel>
                <ThemedInput id="youtube" {...register('youtube')} />
                <ThemedFormErrorMessage>
                  {errors.youtube?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="twitter">Twitter</ThemedLabel>
                <ThemedInput id="twitter" {...register('twitter')} />
                <ThemedFormErrorMessage>
                  {errors.twitter?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="title">Title</ThemedLabel>
                <ThemedInput id="title" {...register('title')} />
                <ThemedFormErrorMessage>
                  {errors.title?.message}
                </ThemedFormErrorMessage>
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter justifyContent="center" pt="46px" pb="48px">
            <ThemedButton
              isDisabled={isSubmitting || isDeleting}
              type="submit"
              height="55px"
              width="158px"
            >
              {isAllowedToUpdate && !!defKeys.length && isSubmitting && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              )}
              {isAllowedToUpdate && !!defKeys.length && !isSubmitting && (
                <Text as="span">Update</Text>
              )}
              {!defKeys.length && isSubmitting && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              )}
              {!defKeys.length && !isSubmitting && (
                <Text as="span">Submit</Text>
              )}
            </ThemedButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateContactForm
