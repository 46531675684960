import {
  Box,
  Flex,
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { FiFilter } from 'react-icons/fi'
import { useEffect, useRef, useState } from 'react'
import useSWR from 'swr'
import { companyRecordAtom } from '../atoms'

import { ThemedTable } from '../components/shared'
import DefaultLayout from '../layouts/DefaultLayout'
import { QueryVariables, BoatProduct } from '../interfaces'
import { fetcher, httpPost } from '../fetchers'
import { BASE_URL } from '../config'
import { CreateBoatProductForm } from '../components/partials'

const columns = [
  'Product Name',
  'Brand Company',
  'Model Year',
  'EBC Status',
  'Published/Unpublished',
]

const MODES = {
  APPROVE: 'Approve',
  REJECT: 'Reject',
}

const MODES_PARAM = {
  Approve: 'APPROVE',
  Reject: 'REJECT',
}

const PendingEnhancedBoatCards: React.FC = () => {
  const [checks, setChecks] = useState([false])
  const [approvalMode, setApprovalMode] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const tableRef = useRef<any>(null)
  const [hasChecks, setHasChecks] = useState(false)
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})
  const [companyRecord] = useAtom(companyRecordAtom)

  const toast = useToast()

  const [variables, setVariables] = useState<QueryVariables>({
    api: '/boat-cards/pending-enhanced-boat-cards',
    first: 25,
    status: 'Pending EBC',
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const {
    data: res,
    error,
    mutate,
  } = useSWR(
    `${variables.api}?first=${variables.first}${
      variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
    }${
      variables.status ? `&status=${encodeURIComponent(variables.status)}` : ''
    }`,
    fetcher
  )
  const isLoading = !error && !res

  const records = res?.data?.Items.map((e: any) => ({
    ...e,
    productName: e?.productName,
    brandCompany: e?.companyId,
    status: e.status,
  }))

  //   .sort((curr: any, next: any) => (curr.show < next.show ? -1 : 1))

  const {
    isOpen: isOpenConfirmModal,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure()
  const {
    isOpen: isOpenViewModal,
    onOpen: onOpenViewModal,
    onClose: onCloseViewModal,
  } = useDisclosure()

  //   const handleNext = () => {
  //     if (res?.data?.LastEvaluatedKey) {
  //       setVariables(v => ({
  //         ...v,
  //         after: res?.data?.LastEvaluatedKey?.showId,
  //         savedCursor: [...v.savedCursor, res?.data?.LastEvaluatedKey?.showId],
  //         currentPage: v.currentPage! + 1,
  //       }))
  //     }
  //   }

  const onRowClick = (row: any) => {
    const record = res?.data?.Items?.find(
      (d: BoatProduct) => d.boatProductId === row.boatProductId
    )

    setDefaultValues({
      ...record,
      boatType: { label: record.boatType, value: record.boatType },
      hullMaterial: { label: record.hullMaterial, value: record.hullMaterial },
      fuelType: { label: record.fuelType, value: record.fuelType },
      modelYear: { label: record.modelYear, value: record.modelYear },
      otherHullColor1:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[0] : '',
      otherHullColor2:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[1] : '',
      otherHullColor3:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[2] : '',
      otherHullColor4:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[3] : '',
      propulsionType: {
        label: record?.propulsionType,
        value: record?.propulsionType,
      },
      status: { label: record?.status, value: record?.status },
    })
    onOpenViewModal()
  }

  const onViewDetailsClose = () => {
    onCloseViewModal()
    setDefaultValues({})
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  const onApprovalClick = async () => {
    try {
      setSubmitting(true)

      const res = await httpPost({
        url: `${BASE_URL}/boat-product/approval`,
        body: {
          // @ts-ignore
          mode: MODES_PARAM[approvalMode],
          checks: checks.filter(Boolean),
        },
      })

      if (res.data) {
        mutate().then(() => {
          tableRef.current.reset()
          setChecks([false])
          toast({
            title: 'The approvals / rejections has been processed succesfully.',
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
        })
      }

      setSubmitting(false)
      onCloseConfirmModal()
    } catch (err) {
      toast({
        title: 'There were errors processing your request.',
        status: 'error',
        duration: 1500,
        isClosable: true,
      })
      setSubmitting(false)
    }
  }

  useEffect(() => {
    mutate().then(() => {
      setChecks([false])
      setHasChecks(false)
      tableRef.current.reset()
    })
  }, [])

  return (
    <>
      <DefaultLayout noXPadding>
        <Flex justifyContent="space-between" alignItems="center" px="49px">
          <Text as="span" fontSize="32px" fontWeight="600">
            Pending Enhanced Boat Cards
          </Text>
        </Flex>
        <Flex justifyContent="end" alignItems="end" px="49px" mt="54px">
          {hasChecks && variables.status === 'Pending EBC' && (
            <>
              <Button
                size="md"
                onClick={() => {
                  onOpenConfirmModal()
                  setApprovalMode(MODES.APPROVE)
                }}
                minW="100px"
                colorScheme="blue"
                marginRight={4}
              >
                <Text as="span">Approve</Text>
              </Button>
            </>
          )}

          {hasChecks && variables.status === 'Approved EBC' && (
            <>
              <Button
                size="md"
                onClick={() => {
                  onOpenConfirmModal()
                  setApprovalMode(MODES.REJECT)
                }}
                minW="100px"
                colorScheme="blue"
                marginRight={4}
              >
                <Text as="span">Move to Pending</Text>
              </Button>
            </>
          )}

          <Box marginLeft={4}>
            <Menu>
              <MenuButton as={Button} rightIcon={<FiFilter />}>
                Filter
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() =>
                    setVariables({ ...variables, status: 'Approved EBC' })
                  }
                >
                  Approved EBC
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    setVariables({ ...variables, status: 'Pending EBC' })
                  }
                >
                  Pending EBC
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
        <ThemedTable
          columns={columns}
          rows={records}
          mt="20px"
          isLoading={isLoading}
          disableNext={!res?.data?.hasNextPage}
          disablePrev={Number(variables.currentPage) + 1 === 1}
          //   handleNext={handleNext}
          handlePrev={handlePrev}
          setChecks={setChecks}
          allowMultipleChecks
          isPendingEbc
          // @ts-ignore
          ref={tableRef}
          setHasChecks={setHasChecks}
          onRowClick={onRowClick}
        />

        <CreateBoatProductForm
          isOpen={isOpenViewModal}
          onClose={onViewDetailsClose}
          mutate={mutate}
          companyId={companyRecord.record?.companyId}
          isCertified={companyRecord.record?.nmmaCertified}
          defaultValues={defaultValues}
          isPendingEbc
          viewOnly
        />

        <Modal
          isOpen={isOpenConfirmModal}
          onClose={() => {
            if (!isSubmitting) {
              onCloseConfirmModal()
            }
          }}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent p="0px" minW="500px">
            <ModalHeader>
              <Text as="span" fontWeight="600" fontSize="20px">
                {approvalMode}
              </Text>
            </ModalHeader>
            <ModalBody paddingRight="32px">
              <Text as="span" fontSize="16px" display="block">
                Are you sure to proceed?
              </Text>
            </ModalBody>
            <ModalFooter justifyContent="end">
              <Button
                size="sm"
                onClick={() => {
                  onCloseConfirmModal()
                }}
                mr="12px"
                isDisabled={isSubmitting}
                minW="100px"
              >
                <Text as="span">Cancel</Text>
              </Button>
              <Button
                size="sm"
                onClick={onApprovalClick}
                isDisabled={isSubmitting}
                minW="100px"
                colorScheme="blue"
              >
                {isSubmitting ? (
                  <Spinner color="gray.500" size="sm" />
                ) : (
                  <Text as="span">Confirm</Text>
                )}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </DefaultLayout>
    </>
  )
}
export default PendingEnhancedBoatCards
