import { Badge } from '@chakra-ui/react'
import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { FiBell } from 'react-icons/fi'

import useNotification from '../../hooks/useNotification'
import { Notification } from '../../interfaces'

type NotificationProps = {
  isOpen?: boolean
  setNotifs: (args: Notification[]) => void
  notifs: Notification[]
}

const Notifications: React.FC<NotificationProps> = ({
  isOpen,
  notifs,
  setNotifs,
}) => {
  const { notifications } = useNotification()

  useEffect(() => {
    if (!isEqual(notifs, notifications)) {
      setNotifs(notifications)
    }
  }, [notifications])

  return (
    <>
      <FiBell
        fontSize="18px"
        color="#BDC6D9"
        {...(notifications?.length && {
          fill: '#BDC6D9',
        })}
        {...(isOpen && { fill: 'white', color: 'white' })}
      />
      {!!notifications?.filter(n => !n.clicked).length && (
        <Badge
          variant="subtle"
          position="absolute"
          top="2px"
          left="20px"
          borderRadius="12px"
        >
          {notifications?.filter(n => !n.clicked).length}
        </Badge>
      )}
    </>
  )
}

export default Notifications
