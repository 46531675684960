/* eslint-disable consistent-return */
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../config'

const useAuthentication = () => {
  const history = useHistory()
  const accessToken = localStorage.getItem('nmma-access-token')
  const user = localStorage.getItem('nmma-user')

  const isAuthenticated = Boolean(accessToken)

  const logoutUser = async () => {
    const response = await axios.post(`${BASE_URL}/user/logout`)

    if (response?.status === 200) {
      localStorage.removeItem('nmma-access-token')
      history.push('/login')
    }
  }

  const setAccessToken = (arg: any) => {
    localStorage.setItem('nmma-access-token', arg)
  }

  const setUser = (arg: any) => {
    localStorage.setItem('nmma-user', arg)
  }

  const clearAuthentication = () => {
    localStorage.clear()
  }

  useEffect(() => {
    if (accessToken === 'true') {
      clearAuthentication()
    }
  }, [accessToken])

  return {
    isAuthenticated,
    setAccessToken,
    clearAuthentication,
    logoutUser,
    user,
    setUser,
  }
}

export default useAuthentication
