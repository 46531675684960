import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'

interface IConfirmationModal {
  isOpen: boolean
  onCancel: () => void
  onSubmit: () => void
  title: string
  isSubmitting?: boolean
}

const ConfirmationModal: React.FC<IConfirmationModal> = ({
  isOpen,
  title,
  isSubmitting,
  onCancel,
  onSubmit,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={() => {
      if (!isSubmitting) {
        onCancel()
      }
    }}
    closeOnOverlayClick={false}
  >
    <ModalOverlay />
    <ModalContent p="0px" minW="500px">
      <ModalHeader>
        <Text as="span" fontWeight="600" fontSize="20px">
          {title}
        </Text>
      </ModalHeader>
      <ModalBody paddingRight="32px">
        <Text as="span" fontSize="16px" display="block">
          Are you sure to proceed?
        </Text>
      </ModalBody>
      <ModalFooter justifyContent="end">
        <Button
          size="sm"
          onClick={() => {
            onCancel()
          }}
          mr="12px"
          isDisabled={isSubmitting}
          minW="100px"
        >
          <Text as="span">Cancel</Text>
        </Button>
        <Button
          size="sm"
          onClick={onSubmit}
          isDisabled={isSubmitting}
          minW="100px"
          colorScheme="blue"
        >
          {isSubmitting ? (
            <Spinner color="gray.500" size="sm" />
          ) : (
            <Text as="span">Confirm</Text>
          )}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

export default ConfirmationModal
