const CURRENT_VERSION = 'v1'

export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `${process.env.REACT_APP_NMMA_API}/${CURRENT_VERSION}`
    : `http://127.0.0.1:4001/${CURRENT_VERSION}`

export const BASE_CLOUDFRONT_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_CLOUDFRONT_LINK
    : 'https://staging-nmma-uploads.data-portal.discoverboating.com'

export const BASE_CLOUDFRONT_IMAGE_RESIZER_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_CLOUDFRONT_IMAGE_RESIZER_LINK
    : 'https://d2g2oug4f37clg.cloudfront.net'

export const AWS_BOOTH_IMG_PROCESSOR =
  'https://eaybbtfp2l.execute-api.us-west-2.amazonaws.com/dev/generateBoothImages'
