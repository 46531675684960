/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Grid,
  useToast,
  Spinner,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Checkbox,
  Flex,
  Switch,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import * as yup from 'yup'
import { useEffect, useState, useRef } from 'react'

import { BASE_URL } from '../../../config'
import {
  CompanyTypeEnum,
  ThemedSelectItem,
  YesNoEnum,
  StatusEnum,
} from '../../../interfaces'
import { useAuthentication, useBoatTypes, useCompany } from '../../../hooks'
import {
  ThemedButton,
  ThemedDragDrop,
  ThemedFormErrorMessage,
  ThemedInput,
  ThemedLabel,
  ThemedModalTrashButton,
  ThemedSelect,
  ThemedTextarea,
} from '../../shared'
import { uploadPhotoToS3, yesOrNo } from '../../../utils'
import ThemedFormHelperMessage from '../../shared/ThemedFormHelperMessage'
import { httpPost } from '../../../fetchers'

type CreateBoatProductFormProps = {
  isOpen: boolean
  onClose: () => void
  mutate?: () => Promise<AxiosResponse<any> | undefined>
  companyId?: string | undefined
  boatTypes?: ThemedSelectItem[]
  defaultValues?: Record<string, any>
  isAllowedToUpdate?: boolean
  isCertified?: boolean
  isPendingEbc?: boolean
  viewOnly?: boolean
}

interface CustomFile extends File {
  fileName: string
  fileNameHashed: string
}

interface BPFormData {
  companyId: string
  productName: string
  basicGallery: CustomFile[]
  ebcGallery: CustomFile[]
  certifiedFlag: ThemedSelectItem
  description: string
  ft: string
  inches: string
  modelYear: ThemedSelectItem | null
  propulsionType: ThemedSelectItem | null
  boatType: ThemedSelectItem | null
  sourceProductCode: string
  startingPrice: string
  used: boolean
  youtubeUrl: string
  beamFt: string
  beamIn: string
  draftFt: string
  draftIn: string
  brandListingUrl: string
  maxPassengerCapacity: string
  boatWeight: string
  maxWeightCapacity: string
  maxBridgeClearanceFt: string
  maxBridgeClearanceIn: string
  hullMaterial: ThemedSelectItem
  hullColor: string
  otherHullColor1: string
  otherHullColor2: string
  otherHullColor3: string
  otherHullColor4: string
  propulsionManufacturer: string
  fuelType: ThemedSelectItem
  maxHorsePower: string
  maxFuelCapacity: string
  numberOfEngines: string
  enhancedBoatCard: boolean
  enhancedDescription: string
  published: boolean
  status: ThemedSelectItem
  futureShows: IFutureShows
  agreedTOS: boolean
  cloneCount: number
  agreedBoatFinderTOS: boolean
  length: number
  contactFirstName: string
  contactLastName: string
  contactEmail: string
}

interface IFutureShows {
  Nashville: boolean
  Chicago: boolean
  Atlanta: boolean
  Minneapolis: boolean
  'New York': boolean
  Louisville: boolean
  Miami: boolean
  'New England': boolean
  'Atlantic City': boolean
  Northwest: boolean
  Norwalk: boolean
}

const boatProductSchema = yup.object().shape({
  productName: yup.string().required('Required.'),
  boatType: yup.object().required('Required.'),
  propulsionType: yup.object().required('Required.'),
  ebcGallery: yup.array().test({
    message: 'Please provide an image to upload.',
    test: arr => {
      if (arr?.length) {
        return arr?.length > 0
      }
      return false
    },
  }),
  length: yup.number().required('Required.').moreThan(0, 'Required.'),
  modelYear: yup.object().required('Required.'),
  sourceProductCode: yup.string(),
  startingPrice: yup.string().required('Required.'),
  used: yup.boolean(),
  brandListingUrl: yup.string().url().required('Required.'),
  enhancedDescription: yup.string().nullable(),
  published: yup.boolean().nullable(),
})

const options = {
  propulsionType: [
    {
      value: 'Hybrid or Electric',
      label: 'Hybrid or Electric',
    },
    {
      value: 'Inboard',
      label: 'Inboard',
    },
    {
      value: 'Jet',
      label: 'Jet',
    },
    {
      value: 'Manual',
      label: 'Manual',
    },
    {
      value: 'Outboard',
      label: 'Outboard',
    },
    {
      value: 'Outboard or Stern Drive',
      label: 'Outboard or Stern Drive',
    },
    {
      value: 'Sail',
      label: 'Sail',
    },
    {
      value: 'Stern Drive',
      label: 'Stern Drive',
    },
  ],
  yesOrNo: [
    { value: true, label: YesNoEnum.YES },
    { value: false, label: YesNoEnum.NO },
  ],
  fuelType: [
    { value: '', label: 'Select...' },
    {
      value: 'Gasoline',
      label: 'Gasoline',
    },
    {
      value: 'Diesel',
      label: 'Diesel',
    },
  ],
  hullMaterial: [
    { value: '', label: 'Select...' },
    {
      value: 'Ferro-cement',
      label: 'Ferro-cement',
    },
    {
      value: 'Wood',
      label: 'Wood',
    },
    {
      value: 'Steel',
      label: 'Steel',
    },
    {
      value: 'Aluminum',
      label: 'Aluminum',
    },
    {
      value: 'Fiberglass',
      label: 'Fiberglass',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ],
  status: [
    { value: StatusEnum.APPROVED_EBC, label: StatusEnum.APPROVED_EBC },
    { value: StatusEnum.PENDING_EBC, label: StatusEnum.PENDING_EBC },
  ],
}

const years = Array.from({
  length: new Date().getFullYear() + 2 - 2009,
})
  .map((v, i) => ({ label: `${2010 + i}`, value: 2010 + i }))
  .sort((curr, next) => next.value - curr.value)

const CreateBoatProductForm: React.FC<CreateBoatProductFormProps> = ({
  isOpen,
  onClose: onFormClose,
  mutate,
  companyId,
  defaultValues = {},
  isCertified,
  isPendingEbc,
  viewOnly,
}) => {
  const toast = useToast()
  const { boatTypes } = useBoatTypes()
  const { companyRecord } = useCompany()

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<BPFormData>({
    defaultValues: {
      companyId,
      ebcGallery: [],
      used: false,
      length: 0,
      ft: '0',
      inches: '0',
      brandListingUrl: '',
      enhancedBoatCard: true,
      enhancedDescription: '',
      published: true,
      cloneCount: 0,
    },
    resolver: yupResolver(boatProductSchema),
  })
  const [hasImageChanged, setImageChanged] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const { isAuthenticated } = useAuthentication()

  const defKeys = Object.keys(defaultValues)

  const onClose = () => {
    clearErrors('basicGallery')
    clearErrors('ebcGallery')
    setIsDeleting(false)
    setImageChanged(false)

    reset()
    onFormClose()
  }

  const onSubmit = async (data: BPFormData) => {
    const forUpdate = defKeys.length
    try {
      await submitForNultipleImage(data)
    } catch (err) {
      const errResponse = (err as any)?.response

      if (errResponse) {
        const { code } = errResponse.data

        if (code === 'ConditionalCheckFailedException') {
          toast({
            title: `Failed to ${
              forUpdate ? 'update' : 'create'
            } a boat product.`,
            description:
              'There is an existing boat product with the same name.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: `Failed to ${
              forUpdate ? 'update' : 'create'
            } a boat product.`,
            description: errResponse.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      } else {
        console.error(err)
      }
    }
  }

  const submitForNultipleImage = async (data: BPFormData) => {
    const forUpdate = defKeys.length
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      basicGallery,
      ebcGallery,
      propulsionType,
      modelYear,
      boatType,
      status,
      ...rest
    } = data

    const filteredEbcGalleryImages = ebcGallery.filter(
      image => image.fileNameHashed === undefined
    )

    await Promise.all([
      await Promise.all(
        filteredEbcGalleryImages.map(async image => presignImages(image))
      ),
    ]).then(async fileResponses => {
      const ebcFileResponse = fileResponses[0]
      const { ebcGallery } = data

      const modifiedEbcFileResponse = ebcFileResponse.map(file => ({
        fileName: file?.fileName,
        fileNameHashed: file?.fileNameHashed,
      }))

      const existingImages: any[] = []
      const imagesForDelete: any[] = []

      if (forUpdate) {
        const existingEbcImages = filterExistingImages(ebcGallery)

        existingImages.push(...existingEbcImages)

        if (defaultValues.gallery?.length > 0) {
          const forDelete = defaultValues.gallery.filter(
            (image: CustomFile) =>
              !existingImages.some(
                (image2: CustomFile) =>
                  image.fileNameHashed === image2.fileNameHashed
              )
          )

          imagesForDelete.push(...forDelete)
        }
      }

      const values = {
        ...rest,
        fileName: '',
        fileNameHashed: '',
        companyId,
        modelYear: modelYear?.value ? Number(modelYear?.value) : null,
        length: Number(Number(rest.ft) + Number(Number(rest.inches) / 12)),
        startingPrice: Number(rest.startingPrice || 0),
        propulsionType: propulsionType?.value,
        boatType: boatType?.value ? boatType?.value : '',
        certifiedFlag: isCertified,
        ...(forUpdate && { boatProductId: defaultValues.boatProductId }),
        ...(forUpdate &&
          !isAuthenticated && {
            isPendingExhibitorBoatProduct: true,
          }),
        gallery: [...existingImages, ...modifiedEbcFileResponse],
        basicGallery: [],
        draft: 0,
        beam: 0,
        maxBridgeClearance: 0,
        otherHullColor: [],
        fuelType: '',
        hullMaterial: '',
        maxWeightCapacity: 0,
        maxPassengerCapacity: 0,
        boatWeight: 0,
        maxHorsePower: 0,
        maxFuelCapacity: 0,
        numberOfEngines: 0,
        status: '',
        cloneCount: rest.cloneCount ? rest.cloneCount : 0,
        contactEmail: '',
        contactFirstName: '',
        contactLastName: '',
        agreedBoatFinderTOS: true,
        agreedTOS: true,
        futureShows: [],
        propulsionManufacturer: '',
        hullColor: '',
        youtubeUrl: '',
        description: '',
      }

      const res = await httpPost({
        url: `${BASE_URL}/boat-product/${forUpdate ? 'update' : 'create'}`,
        body: values,
      })

      if (res?.status === 200) {
        const mergedResponses = [...ebcFileResponse]

        if (
          imagesForDelete.length > 0 &&
          hasImageChanged &&
          !defaultValues?.isCloned
        ) {
          await Promise.all(
            imagesForDelete.map(async image => {
              await httpPost({
                url: `${BASE_URL}/images/delete`,
                body: {
                  fileNameHashed: image.fileNameHashed,
                },
              })
            })
          )
        }

        await Promise.all(
          mergedResponses.map(async file => {
            await uploadPhotoToS3({
              url: file.url,
              file: file.image,
              defaultValues,
            })
          })
        )
        onClose()

        setTimeout(() => {
          mutate?.().then(() => {
            toast({
              title: `Boat product ${forUpdate ? 'updated' : 'created'}.`,
              description: `The boat product has been ${
                forUpdate ? 'updated' : 'created'
              }.`,
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
          })
        }, 1500)
      }
    })
  }

  const presignImages = async (image: CustomFile) => {
    const file = {
      fileName: image.name,
      fileType: image.type,
    }

    const presignedResponse = await httpPost({
      url: `${BASE_URL}/getPresignedUrl`,
      body: file,
    })

    return {
      ...presignedResponse.data,
      image,
    }
  }

  const filterExistingImages = (gallery: any[]) =>
    gallery
      .filter(
        (image: { fileNameHashed: undefined }) =>
          image.fileNameHashed !== undefined
      )
      .map((image: { fileNameHashed: any; name: any }) => ({
        fileNameHashed: image.fileNameHashed,
        fileName: image.name,
      }))

  const onDelete = async () => {
    setIsDeleting(true)
    if (defaultValues?.boatProductId) {
      try {
        const res = await httpPost({
          url: `${BASE_URL}/boat-product/delete`,
          body: {
            companyId,
            boatProductId: defaultValues?.boatProductId,
          },
        })

        if (res?.status === 200) {
          if (!defaultValues?.isCloned) {
            if (defaultValues.gallery.length > 0) {
              Promise.all(
                defaultValues.gallery.map(async (image: CustomFile) => {
                  await httpPost({
                    url: `${BASE_URL}/images/delete`,
                    body: {
                      fileNameHashed: image.fileNameHashed,
                    },
                  })
                })
              )
            } else if (defaultValues.basicGallery.length > 0) {
              Promise.all(
                defaultValues.basicGallery.map(async (image: CustomFile) => {
                  await httpPost({
                    url: `${BASE_URL}/images/delete`,
                    body: {
                      fileNameHashed: image.fileNameHashed,
                    },
                  })
                })
              )
            } else {
              await httpPost({
                url: `${BASE_URL}/images/delete`,
                body: {
                  fileNameHashed: defaultValues?.fileNameHashed,
                },
              })
            }
          }

          onClose()

          setTimeout(() => {
            mutate?.().then(() => {
              toast({
                title: 'Boat product has been deleted.',
                description: 'The boat product has been deleted.',
                status: 'success',
                duration: 1500,
                isClosable: true,
              })
            })
          }, 1500)
        }
      } catch (err) {
        const errResponse = (err as any)?.response

        toast({
          title: 'Failed to delete a boat product.',
          description: errResponse.data.message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
        setIsDeleting(false)
      }
    }
  }

  const watchedFt = watch('ft')
  const watchedIn = watch('inches')
  const watchedImages = watch('basicGallery')
  const ebcGalleryField = register('ebcGallery')
  const watchedEbcGallery = watch('ebcGallery')

  useEffect(() => {
    setValue(
      'length',
      Number(Number(watchedFt) + Number(Number(watchedIn) / 12))
    )
  }, [watchedFt, watchedIn])

  useDeepCompareEffect(() => {
    if (watchedEbcGallery.length && errors.ebcGallery?.message) {
      clearErrors('ebcGallery')
    }
  }, [watchedEbcGallery, watchedImages])

  useDeepCompareEffect(() => {
    if (defKeys.length) {
      defKeys.forEach(key => {
        setValue(key as keyof BPFormData, defaultValues[key])
      })

      if (defaultValues?.length) {
        setValue('ft', `${Math.floor(defaultValues.length)}`)
        setValue(
          'inches',
          `${Number(
            Number(
              (defaultValues?.length - Math.floor(defaultValues.length)) * 12
            ).toFixed(2)
          )}`
        )
      }
    } else {
      reset()
    }
  }, [defaultValues])

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      onCloseComplete={reset}
    >
      <ModalOverlay />
      <ModalContent p="0px" minW="700px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center" pt="50px">
            <Text as="span" fontWeight="600" fontSize="32px">
              {defKeys.length ? 'Boat Details' : 'Add a Boat'}
            </Text>
          </ModalHeader>
          {!!defKeys.length && !viewOnly && (
            <ThemedModalTrashButton
              onClick={onDelete}
              isDeleting={isDeleting}
              entity="boat-product"
            />
          )}
          <ModalCloseButton
            color="mediumGray"
            marginTop="15px"
            marginRight="10px"
            isDisabled={isSubmitting || isDeleting}
          />
          <ModalBody paddingLeft="32px" paddingRight="32px">
            <Grid
              mt="20px"
              templateColumns="repeat(2,1fr)"
              autoColumns="max-content"
              autoRows="auto"
              rowGap="18px"
              columnGap="16px"
            >
              <Box gridColumn="1/3">
                <Text as="span" fontSize="13px">
                  * indicates a required field.
                </Text>
              </Box>
              <Box>
                <ThemedLabel htmlFor="productName">Product Name *</ThemedLabel>
                <ThemedInput
                  id="productName"
                  {...register('productName')}
                  isDisabled={viewOnly}
                />
                <ThemedFormErrorMessage>
                  {errors.productName?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="sourceProductCode">
                  Source Product Code
                </ThemedLabel>
                <ThemedInput
                  id="sourceProductCode"
                  {...register('sourceProductCode')}
                  isDisabled={viewOnly}
                />
                {errors.sourceProductCode?.message ? (
                  <ThemedFormErrorMessage>
                    {errors.sourceProductCode?.message}
                  </ThemedFormErrorMessage>
                ) : (
                  <ThemedFormHelperMessage mt="4px">
                    Internal Use Only. Product SKU or Unique Identifier
                  </ThemedFormHelperMessage>
                )}
              </Box>

              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="ebcGallery">
                  Upload Image *
                </ThemedLabel>
                <ThemedDragDrop
                  id="ebcGallery"
                  {...ebcGalleryField}
                  ref={ebcGalleryField.ref}
                  setValue={setValue}
                  isMultipleUpload
                  gallery={
                    defaultValues.gallery?.length > 0 ?
                    defaultValues.gallery : defaultValues.basicGallery
                  }
                  isDisabled={viewOnly}
                  isEBC
                />
                <ThemedFormErrorMessage>
                  {errors.ebcGallery?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box gridColumn="1/3">
                <Text as="span" fontWeight="600" fontSize="18px">
                  Boat Card Options
                </Text>
              </Box>
              <Box>
                <ThemedLabel htmlFor="boatType">Boat Type *</ThemedLabel>
                <Controller
                  control={control}
                  name="boatType"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="boatType"
                      options={boatTypes || []}
                      isSearchable
                      defaultValue={defaultValues?.boatType}
                      maxWidthOptions={280}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                      isDisabled={viewOnly}
                    />
                  )}
                />
                <ThemedFormErrorMessage>
                  {errors.boatType?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="modelYear">Model Year *</ThemedLabel>

                <Controller
                  control={control}
                  name="modelYear"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="modelYear"
                      options={years}
                      isSearchable
                      defaultValue={defaultValues?.modelYear}
                      maxWidthOptions={280}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                      isDisabled={viewOnly}
                    />
                  )}
                />

                <ThemedFormErrorMessage>
                  {errors.modelYear?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="length">Length *</ThemedLabel>
                <Flex>
                  <InputGroup maxW="100px" mr="8px">
                    <ThemedInput
                      id="ft"
                      isDisabled={viewOnly}
                      pr="36px"
                      {...register('ft')}
                      type="number"
                      pattern="^\d*(\d{0,2})?$"
                      step="1"
                      onKeyDown={e => {
                        if (
                          e.key === '-' ||
                          e.key === '+' ||
                          e.key === 'e' ||
                          e.key === 'E' ||
                          e.key === '.'
                        ) {
                          e.preventDefault()
                          return false
                        }
                        return true
                      }}
                      onBlur={() => {
                        if (
                          Number(watch('ft')) < 1 ||
                          Number.isNaN(Number(watch('ft')))
                        ) {
                          setValue('ft', '0')
                          setValue('inches', '0')
                        } else if (Number(watch('ft')) >= 100) {
                          setValue('ft', '100')
                          setValue('inches', '0')
                        }
                      }}
                    />
                    <InputRightElement
                      pointerEvents="none"
                      color="darkGray"
                      fontSize="md"
                      mt="5px"
                      zIndex="0"
                    >
                      ft
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup maxW="100px">
                    <ThemedInput
                      id="inches"
                      isDisabled={viewOnly}
                      {...register('inches')}
                      type="number"
                      pattern="^\d*(\.\d{0,2})?$"
                      step=".01"
                      pr="36px"
                      onKeyDown={e => {
                        if (
                          e.key === '-' ||
                          e.key === '+' ||
                          e.key === 'e' ||
                          e.key === 'E'
                        ) {
                          e.preventDefault()
                          return false
                        }
                        return true
                      }}
                      onBlur={() => {
                        const inches = Number(watch('inches'))

                        if (inches <= 0 || Number.isNaN(inches)) {
                          setValue('inches', '0')
                        } else if (inches >= 12) {
                          setValue('inches', '12')
                        }
                      }}
                    />
                    <InputRightElement
                      pointerEvents="none"
                      color="darkGray"
                      fontSize="md"
                      mt="5px"
                      zIndex="0"
                    >
                      in
                    </InputRightElement>
                  </InputGroup>
                </Flex>
                <ThemedFormHelperMessage mt="4px">
                  1 ft = 12 inches
                </ThemedFormHelperMessage>
                <ThemedFormErrorMessage>
                  {errors.length?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box>
                <ThemedLabel htmlFor="propulsionType">
                  Propulsion Type *
                </ThemedLabel>
                <Controller
                  control={control}
                  name="propulsionType"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="propulsionType"
                      isDisabled={viewOnly}
                      options={options.propulsionType}
                      isSearchable
                      defaultValue={defaultValues?.propulsionType}
                      maxWidthOptions={280}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
                <ThemedFormErrorMessage>
                  {errors.propulsionType?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box>
                <ThemedLabel htmlFor="certifiedFlag">
                  Certified Flag
                </ThemedLabel>
                <ThemedInput value={yesOrNo(isCertified ?? '')} isDisabled />
              </Box>

              <Box>
                <ThemedLabel htmlFor="startingPrice">
                  Starting Price *
                </ThemedLabel>

                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="darkGray"
                    fontSize="md"
                    mt="5px"
                    zIndex="0"
                  >
                    $
                  </InputLeftElement>
                  <ThemedInput
                    id="startingPrice"
                    isDisabled={viewOnly}
                    {...register('startingPrice')}
                    type="number"
                    pattern="^\d*(\.\d{0,2})?$"
                    step=".01"
                    pl="2rem"
                    min={0}
                    onKeyDown={e => {
                      if (
                        e.key === '-' ||
                        e.key === '+' ||
                        e.key === 'e' ||
                        e.key === 'E'
                      ) {
                        e.preventDefault()
                        return false
                      }
                      return true
                    }}
                  />
                </InputGroup>

                <ThemedFormErrorMessage>
                  {errors.startingPrice?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="brandListingUrl">
                  Brand Listing URL *
                </ThemedLabel>
                <ThemedInput
                  id="brandListingUrl"
                  {...register('brandListingUrl')}
                  isDisabled={viewOnly}
                />
                <ThemedFormHelperMessage mt="4px">
                  Please add your URL that displays this boat model on
                  your website
                </ThemedFormHelperMessage>
                <ThemedFormErrorMessage>
                  {errors.brandListingUrl?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box gridColumn="1/3">
                <Box gridColumn="1 / 3">
                  <ThemedLabel htmlFor="enhancedDescription">
                    Description
                  </ThemedLabel>

                  <ThemedTextarea
                    id="enhancedDescription"
                    {...register('enhancedDescription')}
                    isDisabled={viewOnly}
                  />
                  <ThemedFormHelperMessage mt="4px">
                    Please add your product description for this boat
                    model
                  </ThemedFormHelperMessage>
                  <ThemedFormErrorMessage>
                    {errors.enhancedDescription?.message}
                  </ThemedFormErrorMessage>
                </Box>
              </Box>

              <Box gridColumn="1/3" display="flex">
                <Box>
                  <Checkbox
                    id="used"
                    {...register('used')}
                    defaultValue={defaultValues.used}
                    disabled
                  >
                    <ThemedLabel htmlFor="used" mt="6px">
                      Used / Brokerage
                    </ThemedLabel>
                  </Checkbox>
                </Box>
              </Box>
              {companyRecord?.record?.type !== CompanyTypeEnum.BRAND && (
                <Box gridColumn="1 / 3">
                  <ThemedLabel htmlFor="description">Description</ThemedLabel>
                  <ThemedTextarea
                    id="description"
                    {...register('description')}
                    isDisabled={viewOnly}
                  />
                  <ThemedFormErrorMessage>
                    {errors.description?.message}
                  </ThemedFormErrorMessage>
                </Box>
              )}
            </Grid>
          </ModalBody>

          <ModalFooter justifyContent="center" pt="46px" pb="48px">
            {!isPendingEbc && (
              <ThemedButton
                isDisabled={isSubmitting || isDeleting}
                type="submit"
                height="55px"
                width="158px"
              >
                {!!defKeys.length && isSubmitting && (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="sm"
                  />
                )}
                {!!defKeys.length && !isSubmitting && (
                  <Text as="span">Update</Text>
                )}
                {!defKeys.length && isSubmitting && (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="sm"
                  />
                )}
                {!defKeys.length && !isSubmitting && (
                  <Text as="span">Submit</Text>
                )}
              </ThemedButton>
            )}
            <Box gridColumn="2/3" style={{ marginLeft: '20px' }}>
              <Flex alignItems="center" justifyContent="flex-end">
                <ThemedLabel
                  htmlFor="published"
                  marginRight="10px"
                  fontSize={18}
                >
                  Published
                </ThemedLabel>

                <Switch
                  id="published"
                  colorScheme="green"
                  size="lg"
                  {...register('published')}
                  defaultValue={defaultValues.published}
                  disabled={viewOnly}
                />
              </Flex>
            </Box>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateBoatProductForm
