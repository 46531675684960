import { atom } from 'jotai'
import { Company, ThemedSelectItem } from '../interfaces'

export const companyRecordAtom = atom<{
  isLoading?: boolean
  record?: Company
  mutate?: any
}>({
  isLoading: true,
})

export const boatProductsAtom = atom<ThemedSelectItem[]>([])
export const boatTypesAtom = atom<ThemedSelectItem[]>([])
export const categoriesAtom = atom<ThemedSelectItem[]>([])
export const contactsAtom = atom<ThemedSelectItem[]>([])
export const exhibitorsAtom = atom<ThemedSelectItem[]>([])
export const productsServicesAtom = atom<ThemedSelectItem[]>([])
export const subCategoriesAtom = atom<
  | {
      [x: string]: ThemedSelectItem[]
    }
  | undefined
>({})
