import { Box, Flex } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import { FiMoreHorizontal } from 'react-icons/fi'
import { BASE_CLOUDFRONT_IMAGE_RESIZER_URL } from '../../config'

type ThemedImagesAtRowProps = {
  uploadedFilesKeys?: string[]
  fileNameHashed?: string
}

const ThemedImagesAtRow: React.FC<ThemedImagesAtRowProps> = ({
  uploadedFilesKeys = [],
  fileNameHashed,
}) => (
  <Box position="relative">
    <motion.div
      style={{
        position: 'relative',
        borderRadius: '10px',
        flex: 'none',
        height: '38px',
        width: '38px',

        ...(fileNameHashed && {
          background: `url(${BASE_CLOUDFRONT_IMAGE_RESIZER_URL}/fit-in/60x60/${fileNameHashed}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
        }),
      }}
    />
    {uploadedFilesKeys?.length > 1 && (
      <Flex
        borderRadius="10px"
        position="absolute"
        backgroundColor="white"
        top="24px"
        left="24px"
        width="24px"
        boxShadow="lg"
      >
        <Box mx="auto" color="gray.500">
          <FiMoreHorizontal />
        </Box>
      </Flex>
    )}
  </Box>
)

export default ThemedImagesAtRow
