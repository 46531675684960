/* eslint-disable max-len */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FiEdit2, FiSave, FiTrash, FiX } from 'react-icons/fi'
import uuid from 'react-uuid'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useHistory } from 'react-router-dom'

import {
  Company,
  CompanyTypeEnum,
  NMMADivisionEnum,
  StatusEnum,
  ThemedSelectItem,
  YesNoEnum,
} from '../../../interfaces'
import {
  ThemedLabel,
  ThemedInput,
  ThemedSelect,
  ThemedFormErrorMessage,
  ThemedDragDrop,
} from '../../shared'
import { useAuthentication } from '../../../hooks'
import { BASE_URL } from '../../../config'
import { uploadPhotoToS3 } from '../../../utils'
import ThemedFormHelperMessage from '../../shared/ThemedFormHelperMessage'
import { httpPost } from '../../../fetchers'

type CompanyFormProps = {
  isLoading?: boolean
  record?: Company
}
interface FormData {
  name: string
  sourceCompanyCode: string
  address: string
  phone: string
  website: string
  facebook: string
  linkedin: string
  youtube: string
  twitter: string
  instagram: string
  type: ThemedSelectItem
  nmmaMembershipStatus: ThemedSelectItem
  nmmaDivision: ThemedSelectItem
  nmmaCertified: ThemedSelectItem
  mraaDealerCertified: ThemedSelectItem
  images?: File[]
  order?: boolean
  micrositeUrl?: string
}

const companySchema = yup.object().shape({
  name: yup
    .string()
    .required('Required.')
    .matches(/^([A-Za-z0-9]+[\ \/&',.-]*)*$/g, {
      message: 'Please provide a valid company name.',
    })
    .test(
      'is-valid-company-name',
      'Company name must not exceed 90 characters.',
      value => (!value ? false : value?.length <= 90)
    ),
  sourceCompanyCode: yup.string().required('Required.'),
  address: yup.string(),
  phone: yup.string(),
  website: yup.string(),
  // .test(
  //   'is-valid-url',
  //   'Please provide a valid url.',
  //   value =>
  //     !value ||
  //     !!value?.match(
  //       /(http[s]?:\/\/|www.|ftp:\/\/){1,2}([-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b(\/?[-a-zA-Z0-9@:%_\+.~#?&\/=]+)?)/gm
  //     )
  // ),
  facebook: yup.string(),
  // .test(
  //   'is-valid-url',
  //   'Please provide a valid url.',
  //   value =>
  //     !value ||
  //     !!value?.match(
  //       /(http[s]?:\/\/|www.|ftp:\/\/){1,2}([-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b(\/?[-a-zA-Z0-9@:%_\+.~#?&\/=]+)?)/gm
  //     )
  // ),
  linkedin: yup.string(),
  // .test(
  //   'is-valid-url',
  //   'Please provide a valid url.',
  //   value =>
  //     !value ||
  //     !!value?.match(
  //       /(http[s]?:\/\/|www.|ftp:\/\/){1,2}([-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b(\/?[-a-zA-Z0-9@:%_\+.~#?&\/=]+)?)/gm
  //     )
  // ),
  youtube: yup
    .string()
    .matches(
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
      {
        message: 'Enter a valid Youtube video URL',
        excludeEmptyString: true,
      }
    ),
  twitter: yup.string(),
  // .test(
  //   'is-valid-url',
  //   'Please provide a valid url.',
  //   value =>
  //     !value ||
  //     !!value?.match(
  //       /(http[s]?:\/\/|www.|ftp:\/\/){1,2}([-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b(\/?[-a-zA-Z0-9@:%_\+.~#?&\/=]+)?)/gm
  //     )
  // ),
  instagram: yup.string(),
  // .test(
  //   'is-valid-url',
  //   'Please provide a valid url.',
  //   value =>
  //     !value ||
  //     !!value?.match(
  //       /(http[s]?:\/\/|www.|ftp:\/\/){1,2}([-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b(\/?[-a-zA-Z0-9@:%_\+.~#?&\/=]+)?)/gm
  //     )
  // ),
  order: yup.bool(),
  micrositeUrl: yup.string(),
})

const options = {
  nmmaMembershipStatus: [
    { value: StatusEnum.ACTIVE, label: StatusEnum.ACTIVE },
    { value: StatusEnum.NONACTIVE, label: StatusEnum.NONACTIVE },
  ],
  nmmaDivision: [
    { value: NMMADivisionEnum.MACD, label: NMMADivisionEnum.MACD },
    { value: NMMADivisionEnum.ASSOC, label: NMMADivisionEnum.ASSOC },
    { value: NMMADivisionEnum.BMD, label: NMMADivisionEnum.BMD },
    { value: NMMADivisionEnum.EMD, label: NMMADivisionEnum.EMD },
    { value: NMMADivisionEnum.NONE, label: NMMADivisionEnum.NONE },
  ],
  companyTypeOptions: [
    {
      value: CompanyTypeEnum.BRAND,
      label: CompanyTypeEnum.BRAND,
    },
    {
      value: CompanyTypeEnum.BROKER,
      label: CompanyTypeEnum.BROKER,
    },
    { value: CompanyTypeEnum.DEALER, label: CompanyTypeEnum.DEALER },
    { value: CompanyTypeEnum.RETAILER, label: CompanyTypeEnum.RETAILER },
  ],
  yesOrNo: [
    { value: true, label: YesNoEnum.YES },
    { value: false, label: YesNoEnum.NO },
  ],
}

const CompanyForm: React.FC<CompanyFormProps> = ({ record, isLoading }) => {
  const [isDisabled, setDisabled] = useState(true)
  const [keys, setKeys] = useState([
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
  ])
  const [isFormReset, setFormReset] = useState(false)
  const [shownDeleteModal, showDeleteModal] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const history = useHistory()

  const { isAuthenticated } = useAuthentication()
  const toast = useToast()
  const cRecord = {
    ...record,
    type: options.companyTypeOptions.find(o => o.value === record?.type),
    nmmaMembershipStatus: options.nmmaMembershipStatus.find(
      o => o.value === record?.nmmaMembershipStatus
    ),
    nmmaDivision: options.nmmaDivision.find(
      o => o.value === record?.nmmaDivision
    ),
    nmmaCertified: options.yesOrNo.find(o => o.value === record?.nmmaCertified),
    mraaDealerCertified: options.yesOrNo.find(
      o => o.value === record?.mraaDealerCertified
    ),
  }

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    clearErrors,
  } = useForm<FormData>({
    resolver: yupResolver(companySchema),
  })

  const [hasImageChanged, setImageChanged] = useState(false)

  const toggle = () => {
    setDisabled((prev: boolean) => !prev)
  }

  const rerenderComponent = () => {
    setKeys([uuid(), uuid(), uuid(), uuid(), uuid()])
  }

  const onSubmit = async (data: FormData) => {
    const { images, ...rest } = data

    try {
      const image: File | null = images?.length ? images[0] : null
      let fileNameHash: string = ''

      const uploadNewImage = async () => {
        const fileValues = {
          fileName: image!.name,
          fileType: image!.type,
        }
        const {
          data: { url, fileNameHashed },
        } = await httpPost({
          url: `${BASE_URL}/getPresignedUrl`,
          body: fileValues,
        })

        await uploadPhotoToS3({ url, file: image! })
        fileNameHash = fileNameHashed
      }

      const deleteExistingImage = async () => {
        await httpPost({
          url: `${BASE_URL}/images/delete`,
          body: {
            fileNameHashed: record?.fileNameHashed,
          },
        })
      }

      if (image && !record?.fileNameHashed) {
        await uploadNewImage()
      }
      if (!image && record?.fileNameHashed) {
        await deleteExistingImage()
      }
      if (image && record?.fileNameHashed && hasImageChanged) {
        await deleteExistingImage()
        await uploadNewImage()
      }

      const values = {
        ...rest,
        sourceCompanyCode: isAuthenticated
          ? rest.sourceCompanyCode
          : record?.sourceCompanyCode,
        name: isAuthenticated ? rest.name : record?.name,
        address: isAuthenticated ? rest.address : record?.address,
        phone: isAuthenticated ? rest.phone : record?.phone,
        mraaDealerCertified: rest.mraaDealerCertified.value,
        nmmaCertified: rest.nmmaCertified.value,
        nmmaDivision: rest.nmmaDivision.value,
        nmmaMembershipStatus: rest.nmmaMembershipStatus.value,
        type: rest.type.value,
        ...(image !== null
          ? {
              fileName: hasImageChanged ? image.name : record?.fileName,
              fileNameHashed: hasImageChanged
                ? fileNameHash
                : record?.fileNameHashed,
            }
          : {
              fileNameHashed: '',
              fileName: '',
            }),
        ...((rest.type.value === CompanyTypeEnum.BRAND ||
          rest.type.value === CompanyTypeEnum.BROKER) && {
          order: rest.order,
          micrositeUrl: rest.order ? data.micrositeUrl : '',
        }),
        ...(rest.type.value !== CompanyTypeEnum.BRAND &&
          rest.type.value !== CompanyTypeEnum.BROKER && {
            order: undefined,
          }),
        instagram: rest.instagram ?? '',
      }

      const res = await httpPost({
        url: `${BASE_URL}/company/update`,
        body: values,
      })

      if (res?.data) {
        toggle()

        toast({
          title: 'Company updated.',
          description: 'The company has been updated.',
          status: 'success',
          duration: 1500,
          isClosable: true,
        })

        history.go(0)
      }
    } catch (err) {
      const errResponse = (err as any)?.response

      if (errResponse) {
        const { code } = errResponse.data

        if (code === 'ConditionalCheckFailedException') {
          toast({
            title: 'Failed to update a company.',
            description: 'There is an existing company with the same name.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: 'Failed to update a company.',
            description: errResponse.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      } else {
        console.error(err)
      }
    }
  }

  const onDeleteCompanyClick = async () => {
    try {
      setDeleting(true)
      const params = {
        companyId: record?.companyId,
      }

      const res = await httpPost({
        url: `${BASE_URL}/company/delete`,
        body: params,
      })

      if (res.data) {
        toast({
          title: `The company has been deleted.`,
          description: 'The company has been deleted.',
          status: 'success',
          duration: 1500,
          isClosable: true,
        })
        history.push('/')
      }
    } catch (err) {
      toast({
        title: 'Failed to delete the company.',
        description: (err as any)?.response?.data?.message,
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
      setDeleting(false)
      showDeleteModal(false)
    }
  }

  useEffect(() => {
    if (record?.companyId) {
      rerenderComponent()
    }
  }, [record?.companyId])

  const resetValues = () => {
    if (cRecord) {
      const defKeys = Object.keys(cRecord)
      if (defKeys.length) {
        defKeys?.forEach(key => {
          // @ts-ignore
          setValue(key as keyof FormData, cRecord?.[key])
        })
      }
    }
    setFormReset(true)
  }

  useDeepCompareEffect(() => {
    if (cRecord) {
      const defKeys = Object.keys(cRecord)
      if (defKeys.length) {
        defKeys?.forEach(key => {
          // @ts-ignore

          setValue(key as keyof FormData, cRecord?.[key])
        })
      }
    }
  }, [cRecord])

  const imagesField = register('images')
  const watchedImages = watch('images')

  useDeepCompareEffect(() => {
    if (watchedImages?.length && errors?.images?.message) {
      clearErrors('images')
    }
  }, [watchedImages, errors])

  const isHidden = !isAuthenticated

  return (
    <Box>
      <form>
        <Flex justifyContent="space-between" alignItems="center">
          {isLoading ? (
            <>
              <Skeleton height="32px" width="200px" />
              <Skeleton h="55px" w="131px" borderRadius="10px" />
            </>
          ) : (
            <>
              <Text as="span" fontSize="32px" fontWeight="600">
                {record?.name}
              </Text>

              {isDisabled ? (
                <Flex>
                  {isAuthenticated && (
                    <Button
                      leftIcon={<FiTrash />}
                      variant="outline"
                      color="red.500"
                      borderColor="red.500"
                      h="55px"
                      w="131px"
                      mr="12px"
                      borderRadius="10px"
                      onClick={() => {
                        showDeleteModal(true)
                      }}
                      isDisabled={isSubmitting}
                      type="button"
                    >
                      <Text as="span" fontWeight="400" fontSize="16px" ml="8px">
                        Delete
                      </Text>
                    </Button>
                  )}
                  <Button
                    leftIcon={<FiEdit2 />}
                    variant="outline"
                    color="violet"
                    borderColor="violet"
                    h="55px"
                    w="131px"
                    borderRadius="10px"
                    onClick={() => {
                      toggle()
                      setFormReset(false)
                    }}
                    isDisabled={isSubmitting}
                    type="button"
                  >
                    <Text as="span" fontWeight="400" fontSize="16px" ml="8px">
                      Edit
                    </Text>
                  </Button>
                </Flex>
              ) : (
                <Flex>
                  <Button
                    leftIcon={<FiX />}
                    variant="outline"
                    color="gray.500"
                    borderColor="gray.500"
                    h="55px"
                    w="131px"
                    mr="16px"
                    borderRadius="10px"
                    onClick={() => {
                      resetValues()
                      toggle()
                    }}
                    isDisabled={isSubmitting}
                    type="button"
                  >
                    <Text as="span" fontWeight="400" fontSize="16px" ml="8px">
                      Cancel
                    </Text>
                  </Button>
                  <Button
                    leftIcon={<FiSave />}
                    variant="outline"
                    color="violet"
                    borderColor="violet"
                    h="55px"
                    w="131px"
                    borderRadius="10px"
                    onClick={e => {
                      e.preventDefault()
                      handleSubmit(onSubmit)(e)
                    }}
                    isDisabled={isSubmitting}
                    type="button"
                  >
                    <Text as="span" fontWeight="400" fontSize="16px" ml="8px">
                      Save
                    </Text>
                  </Button>
                </Flex>
              )}
            </>
          )}
        </Flex>
        <Box
          backgroundColor="white"
          p="32px 29px"
          borderRadius="10px"
          mt="49px"
          minH="600px"
        >
          {isLoading ? (
            <Flex h="100%">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="violet"
                size="lg"
                mx="auto"
                my="auto"
                mt="2rem"
              />
            </Flex>
          ) : (
            <>
              <Grid
                templateColumns="repeat(2,minmax(360px, 1fr))"
                autoColumns="max-content"
                autoRows="auto"
                rowGap="18px"
                columnGap="16px"
              >
                <Box gridColumn="1/3">
                  <ThemedLabel htmlFor="images">Upload Logo</ThemedLabel>
                  <ThemedDragDrop
                    id="images"
                    {...imagesField}
                    ref={imagesField.ref}
                    setValue={setValue}
                    fileNameHashed={record?.fileNameHashed}
                    fileName={record?.fileName}
                    hasImageChanged={hasImageChanged}
                    setImageChanged={setImageChanged}
                    isDisabled={isDisabled}
                    isSingleUpload
                    isFormReset={isFormReset}
                  />
                  <ThemedFormErrorMessage>
                    {errors.images?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box>
                  <ThemedLabel htmlFor="sourceCompanyCode">
                    Source Company Code
                  </ThemedLabel>
                  <ThemedInput
                    id="sourceCompanyCode"
                    defaultValue={record?.sourceCompanyCode}
                    isDisabled={isDisabled || !isAuthenticated}
                    {...register('sourceCompanyCode')}
                  />
                  {errors.sourceCompanyCode?.message ? (
                    <ThemedFormErrorMessage>
                      {errors.sourceCompanyCode?.message}
                    </ThemedFormErrorMessage>
                  ) : (
                    <ThemedFormHelperMessage
                      isDisabled={isDisabled || !isAuthenticated}
                      mt="4px"
                    >
                      Internal Use Only
                    </ThemedFormHelperMessage>
                  )}
                </Box>
                <Box>
                  <ThemedLabel htmlFor="name">Name</ThemedLabel>
                  <ThemedInput
                    id="name"
                    isDisabled={isDisabled || !isAuthenticated}
                    defaultValue={record?.name}
                    {...register('name')}
                  />
                  <ThemedFormErrorMessage>
                    {errors.name?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box>
                  <ThemedLabel htmlFor="address">Address</ThemedLabel>
                  <ThemedInput
                    id="address"
                    isDisabled={isDisabled || !isAuthenticated}
                    defaultValue={record?.address}
                    {...register('address')}
                  />
                  <ThemedFormErrorMessage>
                    {errors.address?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box>
                  <ThemedLabel htmlFor="phone">Phone</ThemedLabel>
                  <ThemedInput
                    id="phone"
                    isDisabled={isDisabled || !isAuthenticated}
                    defaultValue={record?.phone}
                    {...register('phone')}
                  />
                  <ThemedFormErrorMessage>
                    {errors.phone?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box>
                  <ThemedLabel htmlFor="website">Website</ThemedLabel>
                  <ThemedInput
                    id="website"
                    isDisabled={isDisabled}
                    defaultValue={record?.website}
                    {...register('website')}
                  />
                  <ThemedFormErrorMessage>
                    {errors.website?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box display={isHidden ? 'none' : 'block'}>
                  <ThemedLabel htmlFor="facebook">Facebook</ThemedLabel>
                  <ThemedInput
                    id="facebook"
                    isDisabled={isDisabled}
                    defaultValue={record?.facebook}
                    {...register('facebook')}
                  />
                  <ThemedFormErrorMessage>
                    {errors.facebook?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box display={isHidden ? 'none' : 'block'}>
                  <ThemedLabel htmlFor="linkedin">Linkedin</ThemedLabel>
                  <ThemedInput
                    id="linkedin"
                    isDisabled={isDisabled}
                    defaultValue={record?.facebook}
                    {...register('linkedin')}
                  />
                  <ThemedFormErrorMessage>
                    {errors.linkedin?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box display={isHidden ? 'none' : 'block'}>
                  <ThemedLabel htmlFor="youtube">Youtube</ThemedLabel>
                  <ThemedInput
                    id="youtube"
                    isDisabled={isDisabled}
                    defaultValue={record?.youtube}
                    {...register('youtube')}
                  />
                  <ThemedFormErrorMessage>
                    {errors.youtube?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box display={isHidden ? 'none' : 'block'}>
                  <ThemedLabel htmlFor="twitter">Twitter</ThemedLabel>
                  <ThemedInput
                    id="twitter"
                    isDisabled={isDisabled}
                    defaultValue={record?.twitter}
                    {...register('twitter')}
                  />
                  <ThemedFormErrorMessage>
                    {errors.twitter?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box display={isHidden ? 'none' : 'block'}>
                  <ThemedLabel htmlFor="instagram">Instagram</ThemedLabel>
                  <ThemedInput
                    id="instagram"
                    isDisabled={isDisabled}
                    defaultValue={record?.instagram}
                    {...register('instagram')}
                  />
                  <ThemedFormErrorMessage>
                    {errors.instagram?.message}
                  </ThemedFormErrorMessage>
                </Box>
                <Box>
                  <ThemedLabel htmlFor="type">Company Type</ThemedLabel>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ThemedSelect
                        id="type"
                        options={options.companyTypeOptions}
                        defaultValue={cRecord?.type}
                        isSearchable={false}
                        maxWidthOptions={349}
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                        isDisabled={isDisabled || !isAuthenticated}
                        key={keys[0]}
                      />
                    )}
                  />
                </Box>

                <Box
                  gridColumn="1/3"
                  display="flex"
                  hidden={
                    watch('type')?.value !== CompanyTypeEnum.BRAND || isHidden
                  }
                >
                  <Box>
                    <Checkbox
                      id="order"
                      {...register('order')}
                      isDisabled={isDisabled}
                      isChecked={watch('order')}
                    >
                      <ThemedLabel htmlFor="order" mt="6px">
                        Order
                      </ThemedLabel>
                    </Checkbox>
                  </Box>
                </Box>
                {watch('order') && !isHidden && (
                  <Box gridColumn="1/3">
                    <ThemedLabel htmlFor="micrositeUrl">
                      Microsite URL
                    </ThemedLabel>
                    <ThemedInput
                      id="micrositeUrl"
                      {...register('micrositeUrl')}
                      isDisabled={isDisabled}
                    />
                    <ThemedFormErrorMessage>
                      {errors.micrositeUrl?.message}
                    </ThemedFormErrorMessage>
                  </Box>
                )}

                <Box gridColumn="1/3">
                  <ThemedLabel htmlFor="nmmaMembershipStatus">
                    NMMA Membership Status
                  </ThemedLabel>
                  <Controller
                    control={control}
                    name="nmmaMembershipStatus"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ThemedSelect
                        id="nmmaMembershipStatus"
                        options={options.nmmaMembershipStatus}
                        defaultValue={cRecord?.nmmaMembershipStatus}
                        isSearchable={false}
                        maxWidthOptions={625}
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                        isDisabled={isDisabled || !isAuthenticated}
                        key={keys[1]}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                templateColumns="repeat(3,1fr)"
                autoColumns="max-content"
                autoRows="auto"
                columnGap="19px"
                mt="18px"
              >
                <Box>
                  <ThemedLabel htmlFor="nmmaDivision">
                    NMMA Division
                  </ThemedLabel>
                  <Controller
                    control={control}
                    name="nmmaDivision"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ThemedSelect
                        id="nmmaDivision"
                        options={options.nmmaDivision}
                        defaultValue={cRecord?.nmmaDivision}
                        isSearchable={false}
                        maxWidthOptions={205}
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                        isDisabled={isDisabled || !isAuthenticated}
                        key={keys[2]}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <ThemedLabel htmlFor="nmmaCertified">
                    Is NMMA Certified
                  </ThemedLabel>
                  <Controller
                    control={control}
                    name="nmmaCertified"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ThemedSelect
                        id="nmmaCertified"
                        options={options.yesOrNo}
                        defaultValue={cRecord?.nmmaCertified}
                        isSearchable={false}
                        maxWidthOptions={220}
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                        isDisabled={isDisabled || !isAuthenticated}
                        key={keys[3]}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <ThemedLabel htmlFor="mraaDealerCertified">
                    Is MRAA Dealer Certified
                  </ThemedLabel>
                  <Controller
                    control={control}
                    name="mraaDealerCertified"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ThemedSelect
                        id="mraaDealerCertified"
                        options={options.yesOrNo}
                        defaultValue={cRecord?.mraaDealerCertified}
                        isSearchable={false}
                        maxWidthOptions={188}
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                        isDisabled={isDisabled || !isAuthenticated}
                        key={keys[4]}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Box>
      </form>
      <Modal
        isOpen={shownDeleteModal}
        onClose={() => {
          if (!isDeleting) {
            showDeleteModal(false)
          }
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent p="0px" minW="500px">
          <ModalHeader>
            <Text as="span" fontWeight="600" fontSize="20px">
              Delete Company
            </Text>
          </ModalHeader>
          <ModalBody paddingRight="32px">
            <Text as="span" fontSize="16px" display="block">
              Are you sure to proceed in deleting this company?
            </Text>
            <Box mt="16px">
              <Text as="span" fontSize="14px">
                This will delete <Text as="strong">all</Text> exhibitors and
                boat products associated to the company.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="end">
            <Button
              size="sm"
              onClick={() => {
                showDeleteModal(false)
              }}
              mr="12px"
              isDisabled={isDeleting}
              minW="100px"
            >
              <Text as="span">Cancel</Text>
            </Button>
            <Button
              size="sm"
              onClick={onDeleteCompanyClick}
              isDisabled={isDeleting}
              minW="100px"
              colorScheme="red"
            >
              {isDeleting ? (
                <Spinner color="gray.500" size="sm" />
              ) : (
                <Text as="span">Confirm</Text>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
export default CompanyForm
