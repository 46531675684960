import useSWR from 'swr'
import { Notification } from '../interfaces/index'
import { fetcher } from '../fetchers'

const useNotification = () => {
  const { data: res, error } = useSWR(`/notifications?first=20`, fetcher, {
    refreshInterval: 1000 * 20,
  })

  const isLoading = !error && !res

  const notifications: Notification[] = res?.data?.Items?.map(
    (n: Notification) => ({ ...n })
  )

  return { isLoading, notifications }
}

export default useNotification
