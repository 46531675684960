import { useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'
import { boatTypesAtom } from '../atoms'
import { fetcher } from '../fetchers'

const useBoatTypes = () => {
  const [boatTypes, setBoatTypes] = useAtom(boatTypesAtom)

  const fetchBoatTypes = useCallback(async () => {
    if (!boatTypes.length) {
      const res = await fetcher('/product-service/categories')
      if (res.data) {
        const boatTypesFromResponse = (
          res.data.find((c: any) => c?.name === 'Boats')
            ?.subCategories as string[]
        )?.reduce((acc, curr) => {
          const t = { label: curr, value: curr }

          return [...acc, t]
        }, [] as { label: string; value: string }[])

        setBoatTypes(boatTypesFromResponse)
      }
    }
  }, [boatTypes.length])

  useEffect(() => {
    fetchBoatTypes()
  }, [fetchBoatTypes])

  return { boatTypes, setBoatTypes }
}

export default useBoatTypes
