import { useAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { companyRecordAtom } from '../atoms'
import { fetcher } from '../fetchers'
import { Company } from '../interfaces'

const useCompany = () => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [companyRecord, setCompanyRecord] = useAtom(companyRecordAtom)
  const location = useLocation()

  const split = location.pathname.split('/')
  const splitName = split[2]

  const fetchCompany = useCallback(async () => {
    setCompanyRecord({ isLoading: true })
    const res = await fetcher(`/company/${splitName}`)
    if (res.data) {
      const companyRecord = res.data?.Items[0] as Company
      setCompanyRecord({ isLoading: false, record: companyRecord })
      setLoading(false)
    } else {
      setCompanyRecord({ isLoading: false })
      setLoading(false)
    }
  }, [splitName])

  useEffect(() => {
    fetchCompany()
  }, [fetchCompany])

  return { companyRecord, setCompanyRecord, fetchCompany, isLoading }
}

export default useCompany
