import { useAtom } from 'jotai'
import { useCallback, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { companyRecordAtom } from '../atoms'
import { fetcher } from '../fetchers'
import { Exhibitor, ThemedSelectItem } from '../interfaces'

const useExhibitors = () => {
  const [exhibitors, setExhibitors] = useState<ThemedSelectItem[]>([])
  const [companyRecord] = useAtom(companyRecordAtom)

  const fetchExhibitors = useCallback(async () => {
    if (companyRecord.record?.companyId) {
      const res = await fetcher(
        `/company/${encodeURIComponent(
          companyRecord.record?.companyId || ''
        )}/exhibitors`
      )
      if (res.data) {
        const exhibitorsFromResponse = res.data?.Items?.reduce(
          (acc: any, curr: Exhibitor) => {
            const t = {
              label: `${curr?.bannerName ?? ''}${
                curr?.show?.showName ? ` (${curr.show.showName})` : ''
              }`,
              value: `${curr?.exhibitorId}~${curr?.showId}`,
              show: curr?.show,
              mapsBooths: curr.mapsBooths?.map(b => {
                const [, mapId, boothId] = b.value.split('::')
                const map =
                  curr?.show?.maps?.find((m: any) => m.id === mapId) || []
                const booth = map?.booths?.find((b: any) => b.id === boothId)

                return {
                  ...b,
                  label: booth?.name ? `${map.name} - ${booth.name}` : '',
                }
              }),
            }
            return [...acc, t]
          },
          [] as { label: string; value: string }[]
        )

        setExhibitors(exhibitorsFromResponse)
      }
    }
  }, [exhibitors.length, companyRecord.record?.companyId])

  useDeepCompareEffect(() => {
    fetchExhibitors()
  }, [exhibitors, companyRecord.record?.companyId])

  return { exhibitors, setExhibitors }
}

export default useExhibitors
