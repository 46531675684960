import { useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'
import { categoriesAtom, subCategoriesAtom } from '../atoms'
import { fetcher } from '../fetchers'
import { Category, ThemedSelectItem } from '../interfaces'

const useCategories = ({ includeBoats = false }) => {
  const [categories, setCategories] = useAtom(categoriesAtom)
  const [subCategories, setSubCategories] = useAtom(subCategoriesAtom)

  const fetchCategories = useCallback(async () => {
    if (!categories.length) {
      const res = await fetcher('/product-service/categories')
      if (res.data) {
        const categoriesRes = (res.data as Category[]).filter(c =>
          !includeBoats ? c.name !== 'Boats' : c.name
        )

        const categoryItems =
          categoriesRes
            .map(c => ({ label: c.name, value: c.name }))
            .sort((curr, next) => (curr.label < next.label ? -1 : 1)) || []

        setCategories(categoryItems)

        setSubCategories(
          categoriesRes.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.name]: curr.subCategories.map(s => ({
                label: s,
                value: s,
                category: curr.name,
              })),
            }),
            {} as {
              [x: string]: ThemedSelectItem[]
            }
          )
        )
      }
    }
  }, [categories.length])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  return { categories, setCategories, subCategories, setSubCategories }
}

export default useCategories
