import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { Flex, Box } from '@chakra-ui/react'
import { memo, useEffect } from 'react'

import Profile from './Profile'
import Contact from './Contact'
import BoatProduct from './BoatProduct'
import ProductsServices from './ProductsServices'
import Exhibitor from './Exhibitor'
import ExhibitorBoatProducts from './ExhibitorBoatProducts'
import ExhibitorProductsServices from './ExhibitorProductsServices'
import { CompanySidebar } from '../../components/shared'
import { companyLinks } from '../../config/links'
import DefaultLayout from '../../layouts/DefaultLayout'
import PublicLayout from '../../layouts/PublicLayout'
import useCompany from '../../hooks/useCompany'
import { useAuthentication } from '../../hooks'

const Container: React.FC<{ isPrivate: boolean }> = memo(
  ({ children, isPrivate }) =>
    isPrivate ? (
      <DefaultLayout noXPadding noYPadding>
        {children}
      </DefaultLayout>
    ) : (
      <PublicLayout>{children}</PublicLayout>
    )
)

const Details: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const split = location.pathname.split('/')

  const { companyRecord, isLoading } = useCompany()
  const { isAuthenticated } = useAuthentication()

  useEffect(() => {
    const isValidName = companyRecord?.record?.companyId

    const isValidSubroute =
      (split.length === 3 && isValidName) ||
      (split.length === 4 &&
        isValidName &&
        !!companyLinks.find(c => c.url === `/${split[3]}`))

    // check if company exist
    if (isLoading === false && isValidSubroute === false) {
      history.push('/404')
    }

    if (!isLoading && !isValidName) {
      history.push('/404')
    }
  }, [isLoading])

  return (
    <Container isPrivate={isAuthenticated}>
      <Flex>
        <CompanySidebar />
        <Box w="100%">
          <Switch>
            <Route
              path="/company/:id"
              render={({ match: { url } }) => (
                <>
                  <Route path={`${url}/`} component={Profile} exact />
                  <Route
                    path={`${url}/contacts`}
                    render={() => (isAuthenticated ? <Contact /> : null)}
                  />
                  <Route path={`${url}/add-boats`} component={BoatProduct} />
                  <Route path={`${url}/boat-product`} component={BoatProduct} />
                  <Route
                    path={`${url}/my-exhibit-space`}
                    render={() => (isAuthenticated ? <Exhibitor /> : null)}
                  />
                  <Route
                    path={`${url}/my-boats-at-the-show`}
                    render={() =>
                      isAuthenticated ? <ExhibitorBoatProducts /> : null
                    }
                  />
                  <Route
                    path={`${url}/add-products-services`}
                    render={() =>
                      isAuthenticated ? <ProductsServices /> : null
                    }
                  />
                  <Route
                    path={`${url}/my-products-services`}
                    render={() =>
                      isAuthenticated ? <ExhibitorProductsServices /> : null
                    }
                  />
                </>
              )}
            />
          </Switch>
        </Box>
      </Flex>
    </Container>
  )
}

export default Details
