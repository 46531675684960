import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Grid,
  useToast,
  Spinner,
  Checkbox,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { KeyedMutator } from 'swr'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import 'yup-phone'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { flatten, flattenDeep, uniqBy, uniqueId } from 'lodash'
import { useAtom } from 'jotai'

import {
  CompanyTypeEnum,
  StatusEnum,
  ThemedSelectItem,
} from '../../../interfaces'
import {
  ThemedButton,
  ThemedFormErrorMessage,
  ThemedInput,
  ThemedLabel,
  ThemedModalTrashButton,
  ThemedSelect,
} from '../../shared'
import { BASE_URL } from '../../../config'
import { httpPost } from '../../../fetchers'
import { useCategories, useContacts, useAuthentication } from '../../../hooks'
import useShows from '../../../hooks/useShows'
import { companyRecordAtom } from '../../../atoms'
import ThemedFormHelperMessage from '../../shared/ThemedFormHelperMessage'

type CreateExhibitorFormProps = {
  isOpen: boolean
  onClose: () => void
  mutate: KeyedMutator<any>
  companyId?: string | undefined
  defaultValues?: Record<string, any>
  isAllowedToUpdate?: boolean
}

interface FormData {
  companyId: string
  sourceExhibitorId: string
  bannerName: string
  show: ThemedSelectItem
  contact: ThemedSelectItem
  status: ThemedSelectItem
  order?: boolean
  category: ThemedSelectItem[]
  mapsBooths: any[]
  subCategory: ThemedSelectItem[]
  micrositeUrl?: string
  salesWeb: string
  salesPhoneNumber: string
}

const exhibitorSchema = yup.object().shape({
  sourceExhibitorId: yup.string(),
  bannerName: yup.string(),
  show: yup.object().shape({
    label: yup.string().required('Required.'),
    value: yup.string().required('Required.'),
  }),
  order: yup.bool(),
  micrositeUrl: yup.string(),
  salesWeb: yup.string(),
  salesPhoneNumber: yup.string().nullable(),
  // .matches(
  //   /^[0-9]{10}$|^[0-9]\d{2}-\d{3}-\d{4}$|^[(][0-9]\d{2}[)]\s\d{3}-\d{4}$/,
  //   {
  //     message:
  //       'Phone number format can be 1234567890, 123-456-7890 (123) 456-7890',
  //     excludeEmptyString: true,
  //   }
  // ),
})

const options = {
  status: [
    {
      value: StatusEnum.ACTIVE,
      label: StatusEnum.ACTIVE,
    },
    {
      value: StatusEnum.PENDING,
      label: StatusEnum.PENDING,
    },
    {
      value: StatusEnum.CANCELLED,
      label: StatusEnum.CANCELLED,
    },
  ],
}

const CreateExhibitorForm: React.FC<CreateExhibitorFormProps> = ({
  isOpen,
  onClose,
  companyId,
  mutate,
  defaultValues = {},
  isAllowedToUpdate = true,
}) => {
  const toast = useToast()
  const { categories, subCategories } = useCategories({ includeBoats: true })
  const { isAuthenticated } = useAuthentication()
  const [companyRecord] = useAtom(companyRecordAtom)
  const { contacts } = useContacts()
  const { shows } = useShows()

  const [mapsBooths, setMapsBooths] = useState<ThemedSelectItem[]>([])
  const [mapsBoothsKey, setMapsBoothsKey] = useState('')

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    setError,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      companyId,
      status: options.status[1],
      salesWeb: '',
      salesPhoneNumber: '',
    },
    resolver: yupResolver(exhibitorSchema),
  })

  const [isDeleting, setIsDeleting] = useState(false)

  const defKeys = Object.keys(defaultValues)

  const watchedMapsBooths = watch('mapsBooths')

  const onSubmit = async (data: FormData) => {
    if (!data.category?.length && data.subCategory?.length) {
      setError('category', { message: 'Please choose a category.' })
      return
    }

    const { contact, show, ...rest } = data

    const forUpdate = defKeys.length

    const values = {
      ...rest,
      ...(rest.bannerName?.length && { bannerName: data.bannerName }),
      ...(rest.category?.length && {
        category: rest.category.map(c => c.value),
      }),
      ...(contact.value && { contactId: contact.value }),
      showId: show?.value,
      status: !isAllowedToUpdate ? StatusEnum.PENDING : rest.status.value,
      ...(rest.subCategory?.length && {
        subCategory: rest.subCategory.map(sc => sc.value),
      }),
      ...(companyRecord.record?.type !== CompanyTypeEnum.BRAND && {
        order: rest.order,
        micrositeUrl: rest.order ? data.micrositeUrl : '',
      }),
      companyId,
      ...(forUpdate && {
        exhibitorId: defaultValues.exhibitorId,
        hasUpdatedShowId: show?.value !== defaultValues?.show?.value,
      }),
    }

    try {
      const res = await httpPost({
        url: `${BASE_URL}/exhibitor/${forUpdate ? 'update' : 'create'}`,
        body: values,
      })

      if (res?.data) {
        mutate().then(() => {
          toast({
            title: `Exhibitor ${forUpdate ? 'updated' : 'created'}.`,
            description: `The exhibitor has been ${
              forUpdate ? 'updated' : 'created'
            }.`,
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
        })
        onClose()
      }
    } catch (err) {
      const errResponse = (err as any)?.response

      if (errResponse) {
        const { code } = errResponse.data

        if (code === 'ConditionalCheckFailedException') {
          toast({
            title: `Failed to ${forUpdate ? 'update' : 'create'} an exhibitor.`,
            description: 'There is an existing exhibitor with the same name.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: `Failed to ${forUpdate ? 'update' : 'create'} an exhibitor.`,
            description: errResponse.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      } else {
        console.error(err)
      }
    }
  }

  const onDelete = async () => {
    setIsDeleting(true)
    if (defaultValues?.exhibitorId) {
      try {
        const res = await httpPost({
          url: `${BASE_URL}/exhibitor/delete`,
          body: {
            companyId,
            exhibitorId: defaultValues?.exhibitorId,
          },
        })

        if (res?.data) {
          mutate?.().then(() => {
            toast({
              title: 'Exhibitor has been deleted.',
              description: 'The exhibitor has been deleted.',
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
          })
          onClose()
        }
      } catch (err) {
        const errResponse = (err as any)?.response

        toast({
          title: 'Failed to delete an exhibitor.',
          description: errResponse.data.message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
        setIsDeleting(false)
      }
    }
  }

  const watchedCategories = watch('category')
  const watchedSubcategories = watch('subCategory')
  const watchedContact = watch('contact')

  useDeepCompareEffect(() => {
    if (defKeys.length) {
      defKeys.forEach(key => {
        setValue(key as keyof FormData, defaultValues[key])
      })
    } else {
      reset()
    }
  }, [defaultValues])

  useDeepCompareEffect(() => {
    if (!watchedContact?.value && contacts?.length) {
      setValue('contact', contacts[0])
    }
  }, [watchedContact, contacts])

  const subCategoryOpts = flatten(
    watchedCategories?.map(
      curr =>
        subCategories?.[curr.label] || {
          value: '',
          label: '',
        }
    )
  ).sort((curr, next) => (curr.label > next.label ? 1 : -1))

  useEffect(() => {
    const isSyncedWithSubcategories = watchedSubcategories?.every(s =>
      watchedCategories.map(c => c.label).includes((s as any).category)
    )

    const uniqueCategories = uniqBy(
      watchedSubcategories?.map(s => ({ category: (s as any).category })),
      (c: any) => c.category
    )

    // When removing a category, all of its subcategories are also removed
    if (
      !isSyncedWithSubcategories &&
      watchedCategories?.length &&
      uniqueCategories?.length
    ) {
      setValue(
        'subCategory',
        watch('subCategory')?.filter(s =>
          watchedCategories.map(c => c.label).includes((s as any).category)
        )
      )
    }
  }, [watchedCategories])

  const isDisabled = !isAuthenticated

  useEffect(() => {
    if ((watch() as any)?.show?.value) {
      setValue(
        'mapsBooths',
        defaultValues?.mapsBooths
          ? defaultValues?.mapsBooths?.filter(
              (mb: any) => mb?.value?.split('::')?.[0] === watch()?.show?.value
            )
          : []
      )
      setMapsBoothsKey(uniqueId())

      const selectedBooths = flattenDeep(
        (
          shows?.find(
            (s: any) => s.value === (watch() as any)?.show?.value
          ) as any
        )?.maps.map((m: any) =>
          m.booths.map((b: any) => ({
            label: `${m.name} - ${b.name}`,
            value: `${(watch() as any)?.show?.value}::${m.id}::${b.id}`,
          }))
        )
      )

      setMapsBooths([
        ...(companyRecord.record?.type === CompanyTypeEnum.DEALER
          ? [
              {
                label: 'No Booth - Not at Show',
                value: '-',
              },
            ]
          : []),
        ...selectedBooths.sort((curr: any, next: any) =>
          curr?.label < next?.label ? -1 : 1
        ),
      ] as ThemedSelectItem[])
    }
  }, [(watch() as any)?.show?.value])

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="0px" minW="700px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center" pt="50px">
            <Text as="span" fontWeight="600" fontSize="32px">
              {defKeys.length
                ? 'My Exhibit Space Details'
                : 'Create Exhibit Space'}
            </Text>
          </ModalHeader>
          {!!defKeys.length && (
            <ThemedModalTrashButton
              onClick={onDelete}
              isDeleting={isDeleting}
              entity="exhibitor"
            />
          )}
          <ModalCloseButton
            color="mediumGray"
            marginTop="15px"
            marginRight="10px"
            isDisabled={isSubmitting || isDeleting}
          />
          <ModalBody paddingLeft="32px" paddingRight="32px">
            <Grid
              mt="20px"
              templateColumns="repeat(2,1fr)"
              autoColumns="max-content"
              autoRows="auto"
              rowGap="18px"
              columnGap="16px"
            >
              <Box>
                <ThemedLabel htmlFor="sourceExhibitorId">
                  Source Exhibitor ID
                </ThemedLabel>
                <ThemedInput
                  id="sourceExhibitorId"
                  {...register('sourceExhibitorId')}
                  isDisabled={isDisabled}
                />
                {errors.sourceExhibitorId?.message ? (
                  <ThemedFormErrorMessage>
                    {errors.sourceExhibitorId?.message}
                  </ThemedFormErrorMessage>
                ) : (
                  <ThemedFormHelperMessage mt="4px">
                    Please select correct exhibitor from the exhibitor list
                    dropdown
                  </ThemedFormHelperMessage>
                )}
                <ThemedFormErrorMessage>
                  {errors.sourceExhibitorId?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="bannerName">
                  Banner Name (optional)
                </ThemedLabel>
                <ThemedInput
                  id="bannerName"
                  {...register('bannerName')}
                  isDisabled={isDisabled}
                />
                {errors.bannerName?.message ? (
                  <ThemedFormErrorMessage>
                    {errors.bannerName?.message}
                  </ThemedFormErrorMessage>
                ) : (
                  <ThemedFormHelperMessage mt="4px">
                    Enter a Consumer friendly display name here. This will
                    appear on the Consumer facing digital show guide
                  </ThemedFormHelperMessage>
                )}
              </Box>

              <Box>
                <ThemedLabel htmlFor="salesWeb">Sales Web</ThemedLabel>
                <ThemedInput id="salesWeb" {...register('salesWeb')} />
                <ThemedFormErrorMessage>
                  {errors.salesWeb?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box>
                <ThemedLabel htmlFor="salesPhoneNumber">
                  Sales Phone Number
                </ThemedLabel>
                <ThemedInput
                  id="salesPhoneNumber"
                  {...register('salesPhoneNumber')}
                />
                <ThemedFormErrorMessage>
                  {errors.salesPhoneNumber?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box>
                <ThemedLabel htmlFor="show">Show</ThemedLabel>
                <Controller
                  control={control}
                  name="show"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="show"
                      options={shows}
                      defaultValue={defaultValues?.show ?? undefined}
                      maxWidthOptions={299}
                      color="black"
                      isSearchable
                      isClearable
                      onBlur={onBlur}
                      onChange={onChange}
                      inputRef={ref}
                      checked={value}
                      isDisabled={isDisabled}
                    />
                  )}
                />
                <ThemedFormErrorMessage>
                  {errors.show?.value?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="contact">Contact</ThemedLabel>
                <Controller
                  control={control}
                  name="contact"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="contact"
                      options={contacts}
                      isSearchable
                      defaultValue={defaultValues.contact ?? contacts[0]}
                      maxWidthOptions={299}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                      isDisabled={isDisabled}
                    />
                  )}
                />
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="status">Status</ThemedLabel>
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="status"
                      options={options.status}
                      isSearchable={false}
                      defaultValue={defaultValues?.status ?? options.status[1]}
                      maxWidthOptions={625}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                      isDisabled={isDisabled}
                    />
                  )}
                />
              </Box>

              {companyRecord.record?.type !== CompanyTypeEnum.BRAND && (
                <>
                  <Box gridColumn="1/3" display="flex">
                    <Box>
                      <Checkbox
                        id="order"
                        {...register('order')}
                        defaultValue={defaultValues.order}
                        isDisabled={isDisabled}
                      >
                        <ThemedLabel htmlFor="order" mt="6px">
                          Order
                        </ThemedLabel>
                      </Checkbox>
                      <ThemedFormHelperMessage mt="4px">
                        Internal Use Only. Product SKU or Unique Identifier
                      </ThemedFormHelperMessage>
                    </Box>
                  </Box>
                  {watch('order') && (
                    <>
                      <Box gridColumn="1/3">
                        <ThemedLabel htmlFor="micrositeUrl">
                          Microsite URL
                        </ThemedLabel>
                        <ThemedInput
                          id="micrositeUrl"
                          {...register('micrositeUrl')}
                          isDisabled={isDisabled}
                        />
                        <ThemedFormErrorMessage>
                          {errors.micrositeUrl?.message}
                        </ThemedFormErrorMessage>
                      </Box>
                    </>
                  )}
                </>
              )}
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="mapsBooths">Maps and Booths</ThemedLabel>
                <Controller
                  control={control}
                  name="mapsBooths"
                  render={({ field: { onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="mapsBooths"
                      options={mapsBooths}
                      key={mapsBoothsKey}
                      defaultValue={defaultValues?.mapsBooths}
                      isSearchable
                      maxWidthOptions={610}
                      isMulti
                      onBlur={onBlur}
                      onChange={(e: any) => {
                        setValue('mapsBooths', e)
                      }}
                      checked={value}
                      inputRef={ref}
                      value={watchedMapsBooths}
                      isDisabled={isDisabled}
                    />
                  )}
                />
              </Box>

              <Box gridColumn="1/3">
                <Text as="span" fontWeight="600" fontSize="18px">
                  Options
                </Text>
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="category">Category</ThemedLabel>
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="category"
                      options={categories}
                      defaultValue={defaultValues?.category}
                      isSearchable
                      maxWidthOptions={610}
                      isMulti
                      onBlur={onBlur}
                      onChange={(e: any) => {
                        const limit = 3
                        if (e?.length <= limit) {
                          setValue('category', e)
                        }
                      }}
                      checked={value}
                      inputRef={ref}
                      value={watchedCategories}
                    />
                  )}
                />
                {errors.category?.message ? (
                  <ThemedFormErrorMessage>
                    {errors.category?.message}
                  </ThemedFormErrorMessage>
                ) : (
                  <ThemedFormHelperMessage mt="4px">
                    Please select from the available Categories. You can only
                    choose up to 3 categories.
                  </ThemedFormHelperMessage>
                )}
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="subCategory">Sub Category</ThemedLabel>
                <Controller
                  control={control}
                  name="subCategory"
                  render={({ field: { onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="subCategory"
                      options={subCategoryOpts}
                      defaultValue={defaultValues?.subCategory}
                      isSearchable
                      maxWidthOptions={610}
                      onBlur={onBlur}
                      onChange={(e: any) => {
                        const limit = 5
                        if (e?.length <= limit) {
                          setValue('subCategory', e)
                        }
                      }}
                      checked={value}
                      inputRef={ref}
                      value={watchedSubcategories}
                      isMulti
                    />
                  )}
                />
                {errors.subCategory?.message ? (
                  <ThemedFormErrorMessage>
                    {errors.subCategory?.message}
                  </ThemedFormErrorMessage>
                ) : (
                  <ThemedFormHelperMessage mt="4px">
                    Please select from the available Sub Categories.You can only
                    choose up to 5 subcategories.
                  </ThemedFormHelperMessage>
                )}
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter justifyContent="center" pt="46px" pb="48px">
            <ThemedButton
              isDisabled={isSubmitting || isDeleting}
              type="submit"
              height="55px"
              width="158px"
            >
              {!!defKeys.length && isSubmitting && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              )}
              {!!defKeys.length && !isSubmitting && (
                <Text as="span">Update</Text>
              )}
              {!defKeys.length && isSubmitting && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              )}
              {!defKeys.length && !isSubmitting && (
                <Text as="span">Submit</Text>
              )}
            </ThemedButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateExhibitorForm
