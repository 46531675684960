import { Box, Flex, Text } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import { FiLogOut } from 'react-icons/fi'

import links from '../../config/links'
import { ReactComponent as DiscoverBoatingLogo } from '../../assets/svg/discover-boating.svg'
import { useAuthentication } from '../../hooks'

type SidebarProps = {
  isPublic?: boolean
}

const Sidebar: React.FC<SidebarProps> = () => {
  const location = useLocation()
  const history = useHistory()
  const { logoutUser, isAuthenticated } = useAuthentication()

  const shownLinks = isAuthenticated ? links : links.filter(l => !l.private)

  return (
    <Box
      minH="100vh"
      maxH="100vh"
      w="245px"
      backgroundColor="black"
      flex="none"
    >
      <Box padding="30px">
        <DiscoverBoatingLogo />
      </Box>
      <Box as="ul" mt="56px">
        {shownLinks.map(l => {
          const path = location.pathname.split('/')[1]
          const subCategoryPath = location.pathname.split('/')[2]
          const isActive = `/${path}` === l.url && !subCategoryPath

          return (
            <Box key={l.label}>
              <Flex
                as="li"
                padding="12px 30px"
                cursor="pointer"
                {...(isActive && {
                  backgroundColor: ' rgba(255, 255, 255, 0.25)',
                })}
                onClick={() => {
                  if (isAuthenticated) {
                    history.push(l.url)
                  }
                }}
                mb="4px"
              >
                <Text as="span" mr="12px" mt="1px">
                  {l.icon}
                </Text>

                <Text as="span" color="white" fontWeight="300" fontSize="16px">
                  {l.label}
                </Text>
              </Flex>
              {l?.subCategories?.map(
                s =>
                  isAuthenticated &&
                  s.private && (
                    <Flex
                      as="li"
                      key={s.label}
                      padding="6px"
                      cursor="pointer"
                      {...(`/${subCategoryPath}` === s.url && {
                        backgroundColor: ' rgba(255, 255, 255, 0.25)',
                      })}
                      onClick={() => {
                        if (isAuthenticated) {
                          history.push(`${l.url}${s.url}`)
                        }
                      }}
                      mb="4px"
                    >
                      <Text
                        as="span"
                        color="white"
                        fontWeight="300"
                        fontSize="14px"
                        ml="56px"
                      >
                        {s.label}
                      </Text>
                    </Flex>
                  )
              )}
            </Box>
          )
        })}
        {isAuthenticated && (
          <Flex
            as="li"
            padding="16px 30px"
            cursor="pointer"
            onClick={() => {
              logoutUser()
            }}
            mb="4px"
            position="absolute"
            bottom="0px"
          >
            <Text as="span" mr="20px" mt="1px">
              <FiLogOut color="white" fontSize="20px" />
            </Text>
            <Text as="span" color="white" fontWeight="300" fontSize="16px">
              Logout
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  )
}

export default Sidebar
