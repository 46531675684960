import { Flex, Text, Image, Box, InputProps, Grid } from '@chakra-ui/react'
import { forwardRef, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { UseFormSetValue } from 'react-hook-form'
import { FiEye, FiPlus, FiTrash, FiX } from 'react-icons/fi'

import {
  BASE_CLOUDFRONT_IMAGE_RESIZER_URL,
  BASE_CLOUDFRONT_URL,
} from '../../config'

import ThemedButton from './ThemedButton'

const EmptyFiles: React.FC<{
  isDisabled?: boolean
  isEBC?: boolean
  isDragActive: boolean
  open: () => void
}> = ({ isDragActive, open, isDisabled, isEBC }) => (
  <>
    {isDragActive && !isDisabled ? (
      <Text as="span" color="darkGray" fontSize="14px" fontWeight="normal">
        Drop the files here...
      </Text>
    ) : (
      <Flex flexDirection="column" alignItems="center">
        {!isDisabled && (
          <Box>
            <Text
              as="span"
              color="darkGray"
              fontSize="14px"
              fontWeight="normal"
            >
              Drag files here or
            </Text>
            <ThemedButton
              w="97px"
              h="30px"
              borderRadius="25px"
              fontSize="14px"
              fontWeight="normal"
              ml="8px"
              onClick={open}
            >
              Add file
            </ThemedButton>
          </Box>
        )}
        {isEBC && (
          <Box marginTop="8px">
            <Text
              as="span"
              color="darkGray"
              fontSize="14px"
              fontWeight="normal"
            >
              Add up to 20 images
            </Text>
          </Box>
        )}
      </Flex>
    )}
  </>
)

type CustomFile = File & { preview: string; fileNameHashed?: string }
type Files = CustomFile[]
type ThumbsProps = {
  files: Files
  onRemove: (preview: string) => void
  open: () => void
  removeAll: () => void
  isSingleUpload?: boolean
  isDisabled?: boolean
}

type ThumbProps = {
  f: CustomFile
  isDisabled?: boolean
  onRemove: (preview: string) => void
}

const Thumb: React.FC<ThumbProps> = ({ f, isDisabled, onRemove }) => {
  const [isHovered, setHovered] = useState(false)

  return (
    <Box
      onMouseOver={() => {
        if (!isHovered && !isDisabled) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (isHovered && !isDisabled) {
          setHovered(false)
        }
      }}
      position="relative"
      key={f.preview}
    >
      <Image
        src={f.preview}
        objectFit="cover"
        height="120px"
        width="120px"
        filter="blur(0.5px) brightness(35%)"
        borderRadius="10px"
      />
      <Image
        position="absolute"
        top="9%"
        left="9%"
        src={f.preview}
        width="100px"
        height="100px"
        objectFit="cover"
      />
      <Box
        position="absolute"
        bottom="0px"
        w="full"
        backgroundColor="black"
        opacity="0.45"
        borderRadius={isHovered ? '10px' : '0px 0px 10px 10px'}
        height={isHovered ? '120px' : '30px'}
      />
      <Box
        position="absolute"
        top="45px"
        right="45px"
        backgroundColor="transparent"
        color="white"
        borderRadius="50px"
        display={isHovered ? 'block' : 'none'}
        onClick={() => {
          if (f.fileNameHashed) {
            window.open(`${BASE_CLOUDFRONT_URL}/${f.fileNameHashed}`, '_blank')
          } else {
            window.open(f.preview, '_blank')
          }
        }}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        title="View Original Image"
      >
        <FiEye fontSize="25px" />
      </Box>
      <Box
        position="absolute"
        top="4px"
        right="4px"
        backgroundColor="transparent"
        color="white"
        borderRadius="50px"
        onClick={() => {
          if (!isDisabled) {
            onRemove(f.preview)
          }
        }}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        title="Remove Image"
        display={isHovered ? 'block' : 'none'}
      >
        <FiX fontSize="19px" />
      </Box>
      <Text
        textAlign="center"
        position="absolute"
        bottom="10px"
        as="span"
        color="white"
        fontSize="11px"
        w="full"
      >
        {`${(f as any).path?.substring(0, 15)}${
          (f as any).path?.length > 15 ? '...' : ''
        }`}
      </Text>
    </Box>
  )
}

const Thumbs: React.FC<ThumbsProps> = ({
  files,
  onRemove,
  open,
  removeAll,
  isDisabled,
}) => (
  <>
    {files.map(f => (
      <Thumb
        key={f.preview}
        f={f}
        isDisabled={isDisabled}
        onRemove={onRemove}
      />
    ))}

    {!files.length && !isDisabled && (
      <Flex
        height="120px"
        width="120px"
        backgroundColor="transparent"
        border="1px dashed"
        borderColor="mediumGray"
        justifyContent="center"
        alignItems="center"
        borderRadius="10px"
        _hover={{
          borderColor: 'blue.500',
          color: 'blue.500',
        }}
        color="darkGray"
        cursor="pointer"
        onClick={open}
      >
        <Flex direction="column" alignItems="center">
          <FiPlus />
          <Text as="span" display="block" fontSize="12px" mt="4px">
            Add file
          </Text>
        </Flex>
      </Flex>
    )}

    {files.length > 4 && !isDisabled && (
      <Flex
        height="120px"
        width="120px"
        backgroundColor="transparent"
        border="1px dashed"
        borderColor="mediumGray"
        justifyContent="center"
        alignItems="center"
        borderRadius="10px"
        _hover={{
          borderColor: 'red.500',
          color: 'red.500',
        }}
        color="darkGray"
        cursor="pointer"
        onClick={removeAll}
      >
        <Flex direction="column" alignItems="center">
          <FiTrash />
          <Text as="span" display="block" fontSize="12px" mt="4px">
            Remove all
          </Text>
        </Flex>
      </Flex>
    )}
  </>
)

interface ThemedDragDropProps extends InputProps {
  setValue?: UseFormSetValue<any>
  // clearErrors?: UseFormClearErrors<any>
  [x: string]: any
}

const ThemedDragDrop: React.FC<ThemedDragDropProps> = forwardRef(
  (
    {
      setValue,
      fileName = '',
      fileNameHashed = '',
      isSingleUpload,
      hasImageChanged,
      setImageChanged,
      isDisabled,
      name = 'images',
      isFormReset,
      isMultipleUpload,
      gallery,
      isEBC,
      ...rest
    },
    ref
  ) => {
    const [files, setFiles] = useState<Files>([])
    const [isRejected, setRejected] = useState(false)
    const [filesExceed, setFilesExceed] = useState(false)
    const [invalidFile, setInvalidfile] = useState(false)
    const maxFiles = isEBC ? 20 : 1

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop: (acceptedFiles: File[]) => {
        const currentFiles = files.length
        const newlyAddedFiles = acceptedFiles.length
        const totalFiles = currentFiles + newlyAddedFiles

        setFilesExceed(false)
        setInvalidfile(false)
        setRejected(false)

        if (totalFiles <= maxFiles && !isDisabled) {
          const newFiles = [
            ...files,
            ...acceptedFiles.map(file => {
              Object.defineProperty(file, 'preview', {
                value: URL.createObjectURL(file),
                writable: false,
              })
              return file
            }),
          ]
          if (hasImageChanged === false) {
            setImageChanged?.(true)
          }
          setFiles(newFiles as Files)
          setValue?.(name, newFiles)
        }

        if (totalFiles > 20) {
          setFilesExceed(true)
        }
      },
      onDropRejected: rejected => {
        setRejected(true)

        rejected.map(({ errors }) => {
          if (errors[0].code === 'file-invalid-type') {
            setInvalidfile(true)
          }

          return errors
        })
      },
      noKeyboard: true,
      noClick: true,
      maxSize: 32000000,
      accept: ['.jpeg', '.png', '.jpg'],
      multiple: isMultipleUpload && isEBC,
    })

    const onRemove = (preview: string) => {
      const newFiles = files.filter(f => f.preview !== preview)
      setFiles(newFiles)
      setValue?.(name, newFiles)
      if (hasImageChanged === false) {
        setImageChanged?.(true)
      }
    }

    const removeAll = () => {
      setFiles([])
      setValue?.(name, [])
      // clearErrors?.('images')
    }

    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview))
      },
      [files]
    )

    useEffect(() => {
      if (gallery && gallery.length > 0) {
        const modifiedFiles = gallery.map((image: any) => {
          const file = new File([''], image.fileName, {
            type:
              image.fileName.split('.').pop() === 'jpg' ||
              image.fileName.split('.').pop() === 'jpeg'
                ? 'image/jpg'
                : 'image/png',
          })

          Object.defineProperty(file, 'preview', {
            value: `${BASE_CLOUDFRONT_IMAGE_RESIZER_URL}/fit-in/200x200/${image.fileNameHashed}`,
            writable: false,
          })

          Object.defineProperty(file, 'fileNameHashed', {
            value: image.fileNameHashed,
            writable: false,
          })

          Object.defineProperty(file, 'path', {
            value: image.fileName,
            writable: false,
          })

          return file
        })

        setFiles(modifiedFiles)
        setValue?.(name, modifiedFiles)
      } else if (fileName) {
        const file = new File([''], fileName, {
          type:
            fileName.split('.').pop() === 'jpg' ||
            fileName.split('.').pop() === 'jpeg'
              ? 'image/jpg'
              : 'image/png',
        })

        Object.defineProperty(file, 'preview', {
          value: `${BASE_CLOUDFRONT_IMAGE_RESIZER_URL}/fit-in/200x200/${fileNameHashed}`,
          writable: false,
        })

        Object.defineProperty(file, 'fileNameHashed', {
          value: fileNameHashed,
          writable: false,
        })

        Object.defineProperty(file, 'path', {
          value: fileName,
          writable: false,
        })

        setFiles([file as CustomFile])
        setValue?.(name, [file])
      }
    }, [gallery, isEBC])

    useEffect(() => {
      if (!gallery?.length && !fileNameHashed) {
        setFiles([])
      }
    }, [isEBC])

    useEffect(() => {
      if (isFormReset && fileName?.length) {
        const file = new File([''], fileName, {
          type:
            fileName.split('.').pop() === 'jpg' ||
            fileName.split('.').pop() === 'jpeg'
              ? 'image/jpg'
              : 'image/png',
        })

        Object.defineProperty(file, 'preview', {
          value: `${BASE_CLOUDFRONT_IMAGE_RESIZER_URL}/fit-in/200x200/${fileNameHashed}`,
          writable: false,
        })

        Object.defineProperty(file, 'path', {
          value: fileName,
          writable: false,
        })

        setFiles([file as CustomFile])
        setValue?.(name, [file])
      }
    }, [isFormReset])

    return (
      <Box
        opacity={isDisabled ? 0.6 : 1}
        cursor={isDisabled ? 'not-allowed' : 'auto'}
      >
        <Flex
          backgroundColor={isDragActive ? 'gray.100' : 'lightGray'}
          border="1px dashed"
          borderColor="mediumGray"
          minHeight="142px"
          borderRadius="10px"
          justifyContent="center"
          alignItems="center"
          direction={isMultipleUpload ? 'column' : 'row'}
          {...(files.length && { py: '10px' })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <input type="hidden" {...(rest as any)} ref={ref} />
          {isSingleUpload && (
            <>
              {files.length > 0 ? (
                <>
                  <Grid
                    {...(!isSingleUpload && {
                      templateColumns: `repeat(${
                        files.length < 4 ? files.length + 1 : 4
                      }, 1fr)`,
                    })}
                    gap="10px"
                  >
                    <Thumbs
                      files={files}
                      onRemove={onRemove}
                      open={open}
                      removeAll={removeAll}
                      isSingleUpload={isSingleUpload}
                      isDisabled={isDisabled}
                    />
                  </Grid>
                </>
              ) : (
                <EmptyFiles
                  isDragActive={isDragActive}
                  open={open}
                  isDisabled={isDisabled}
                />
              )}
            </>
          )}

          {isMultipleUpload && (
            <>
              <Flex>
                {files.length > 0 && (
                  <>
                    <Flex minWidth="120px" flexWrap="wrap">
                      <Thumbs
                        files={files}
                        onRemove={onRemove}
                        open={open}
                        removeAll={removeAll}
                        isSingleUpload={isSingleUpload}
                        isDisabled={isDisabled}
                      />
                    </Flex>
                  </>
                )}
              </Flex>
              {files.length < maxFiles && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  marginTop="20px"
                >
                  <EmptyFiles
                    isDragActive={isDragActive}
                    open={open}
                    isDisabled={isDisabled}
                    isEBC={isEBC}
                  />
                </Flex>
              )}
            </>
          )}
        </Flex>

        <Text
          as="span"
          display="block"
          fontWeight="400"
          color="darkGray"
          fontSize="11px"
          mt="10px"
        >
          File Format: JPG or PNG. Maximum upload file size: 32MB
        </Text>

        {!!invalidFile && (
          <Text
            as="span"
            display="block"
            fontWeight="400"
            color="red.600"
            fontSize="11px"
            mt="5px"
          >
            Your file is invalid.
          </Text>
        )}

        {!!isRejected && !invalidFile && (
          <Text
            as="span"
            display="block"
            fontWeight="400"
            color="red.600"
            fontSize="11px"
            mt="5px"
          >
            Your file exceeds the maximum upload file size.
          </Text>
        )}

        {!!filesExceed && (
          <Text
            as="span"
            display="block"
            fontWeight="400"
            color="red.600"
            fontSize="11px"
            mt="5px"
          >
            Maximum files allowed is 20.
          </Text>
        )}
      </Box>
    )
  }
)

export default ThemedDragDrop
