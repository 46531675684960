import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { useState } from 'react'
import useSWR from 'swr'
import { DateTime as dt } from 'luxon'
import uuid from 'react-uuid'

import { ThemedButton, ThemedTable } from '../components/shared'
import DefaultLayout from '../layouts/DefaultLayout'
import { CreateShowForm } from '../components/partials'
import { Show, QueryVariables } from '../interfaces'
import { fetcher } from '../fetchers'

const columns = [
  'ID',
  'Show Name',
  'Status',
  'Producer Event Code',
  'Event Family',
  'Description',
  'Location',
  'Venue',
  'Start Date',
  'End Date',
  'Website',
]

const Shows: React.FC = () => {
  const [variables, setVariables] = useState<QueryVariables>({
    api: '/show/list',
    first: 25,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    `${variables.api}?first=${variables.first}${
      variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
    }`,
    fetcher
  )
  const isLoading = !error && !res
  const records = res?.data?.Items?.map((d: Show) => {
    const { showId, family, startDate, endDate, ...rest } = d
    return {
      id: showId,
      eventFamily: family,
      startDate: dt.fromISO(startDate).toFormat('LLL dd yyyy'),
      endDate: dt.fromISO(endDate).toFormat('LLL dd yyyy'),
      ...rest,
    }
  })

  const [key, setKey] = useState('')

  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setDefaultValues({})
    setTimeout(() => {
      setKey(uuid())
    }, 300)
  }

  const onRowClick = (row: any) => {
    const record = res?.data?.Items?.find((d: Show) => d.showId === row.id)

    setDefaultValues({
      ...record,
      status: { label: record.status, value: record.status },
      startDate: dt.fromISO(record.startDate).toJSDate(),
      endDate: dt.fromISO(record.endDate).toJSDate(),
    })
    onOpen()
  }

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: res?.data?.LastEvaluatedKey?.showId,
        savedCursor: [...v.savedCursor, res?.data?.LastEvaluatedKey?.showId],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  return (
    <>
      <DefaultLayout noXPadding>
        <Flex justifyContent="space-between" alignItems="center" px="49px">
          <Text as="span" fontSize="32px" fontWeight="600">
            Shows
          </Text>
          <ThemedButton
            height="55px"
            width="158px"
            type="button"
            onClick={onOpen}
            leftIcon={<FiPlus color="white" fontSize="22px" />}
          >
            <Text as="span" ml="8px">
              Create
            </Text>
          </ThemedButton>
        </Flex>
        <ThemedTable
          columns={columns}
          rows={records}
          mt="60px"
          isLoading={isLoading}
          onRowClick={onRowClick}
          disableNext={!res?.data?.hasNextPage}
          disablePrev={Number(variables.currentPage) + 1 === 1}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      </DefaultLayout>
      <CreateShowForm
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
        key={key}
        defaultValues={defaultValues}
      />
    </>
  )
}
export default Shows
