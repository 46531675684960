import {
  Button,
  ButtonProps,
  Spinner,
  useDisclosure,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
} from '@chakra-ui/react'
import { startCase } from 'lodash'
import { ReactNode } from 'react'
import { FiTrash } from 'react-icons/fi'

interface ThemedModalTrashButtonProps extends ButtonProps {
  isDeleting?: boolean
  entity?: string
  message?: ReactNode
  customWidth?: string
}

const getFormattedEntity = (arg: string | undefined) =>
  arg
    ?.split('-')
    .map(s => startCase(s))
    .join(' ')

const ThemedModalTrashButton: React.FC<ThemedModalTrashButtonProps> = ({
  isDeleting,
  onClick,
  entity,
  message,
  customWidth,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const formattedEntity = getFormattedEntity(entity)

  return (
    <>
      <Button
        position="absolute"
        right="58px"
        top="23px"
        fontSize="md"
        color="red.500"
        backgroundColor="transparent"
        _hover={{
          backgroundColor: 'red.100',
        }}
        _active={{
          backgroundColor: 'red.200',
        }}
        size="sm"
        px="2px"
        onClick={onOpen}
        {...rest}
      >
        <FiTrash />
      </Button>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => {
          if (!isDeleting) {
            onClose()
          }
        }}
      >
        <ModalOverlay />
        <ModalContent p="0px" minW={customWidth ?? '400px'}>
          <ModalHeader>
            <Text as="span" fontWeight="600" fontSize="20px">
              Delete {formattedEntity}
            </Text>
          </ModalHeader>
          <ModalBody paddingRight="32px">
            <Text as="span" fontSize="16px" display="block">
              Are you sure to proceed deleting this{' '}
              {formattedEntity?.toLowerCase()}?
            </Text>
            {message && (
              <Box mt="16px">
                <Text as="span" fontSize="14px">
                  {message}
                </Text>
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent="end">
            <Button
              size="sm"
              onClick={onClose}
              mr="12px"
              isDisabled={isDeleting}
              minW="100px"
            >
              <Text as="span">Cancel</Text>
            </Button>
            <Button
              size="sm"
              onClick={onClick}
              isDisabled={isDeleting}
              minW="100px"
              colorScheme="red"
            >
              {isDeleting ? (
                <Spinner color="gray.500" size="sm" />
              ) : (
                <Text as="span">Confirm</Text>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ThemedModalTrashButton
