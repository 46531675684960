import {
  Flex,
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { useRef, useState, useEffect } from 'react'
import useSWR from 'swr'

import { ThemedTable } from '../components/shared'
import DefaultLayout from '../layouts/DefaultLayout'
import { QueryVariables } from '../interfaces'
import { fetcher, httpPost } from '../fetchers'
import { BASE_URL } from '../config'

const columns = [
  'Show',
  'Company Name',
  'Banner Name',
  'Approval Status',
  'Boats at the Show',
  'ID',
]

const MODES = {
  APPROVE: 'Approve',
  REJECT: 'Reject',
}

const MODES_PARAM = {
  Approve: 'APPROVE',
  Reject: 'REJECT',
}

const PendingExhibitors: React.FC = () => {
  const [checks, setChecks] = useState([false])
  const [approvalMode, setApprovalMode] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const tableRef = useRef<any>(null)
  const [hasChecks, setHasChecks] = useState(false)

  const toast = useToast()

  const [variables, setVariables] = useState<QueryVariables>({
    api: '/exhibitor/pending-exhibitors',
    first: 25,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const {
    data: res,
    error,
    mutate,
  } = useSWR(
    `${variables.api}?first=${variables.first}${
      variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
    }`,
    fetcher
  )
  const isLoading = !error && !res
  const records = res?.data?.Items.map((e: any) => ({
    ...e,
    showId: e.show?.showId,
    show: e?.show?.showName,
    companyName: `${e?.company?.name}`,
    bannerName: `${e?.bannerName}`,
    approvalStatus: e.status,
    boatsAtTheShow: `${e.exhibitorBoatProducts.length}`,
    id: e.exhibitorId,
  })).sort((curr: any, next: any) => (curr.show < next.show ? -1 : 1))

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: res?.data?.LastEvaluatedKey?.showId,
        savedCursor: [...v.savedCursor, res?.data?.LastEvaluatedKey?.showId],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  const onApprovalClick = async () => {
    try {
      setSubmitting(true)

      const res = await httpPost({
        url: `${BASE_URL}/exhibitor/approval`,
        body: {
          // @ts-ignore
          mode: MODES_PARAM[approvalMode],
          checks: checks.filter(Boolean),
        },
      })

      if (res.data) {
        mutate().then(() => {
          tableRef.current.reset()
          setChecks([false])
          toast({
            title: 'The approvals / rejections has been processed succesfully.',
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
        })
      }

      setSubmitting(false)
      onClose()
    } catch (err) {
      toast({
        title: 'There were errors processing your request.',
        status: 'error',
        duration: 1500,
        isClosable: true,
      })
      setSubmitting(false)
    }
  }

  useEffect(() => {
    mutate().then(() => {
      setChecks([false])
      setHasChecks(false)
      tableRef.current.reset()
    })
  }, [])

  return (
    <>
      <DefaultLayout noXPadding>
        <Flex justifyContent="space-between" alignItems="center" px="49px">
          <Text as="span" fontSize="32px" fontWeight="600">
            Pending Exhibitors
          </Text>
        </Flex>
        <Flex justifyContent="end" alignItems="end" px="49px" mt="54px">
          {hasChecks && (
            <>
              <Button
                size="md"
                onClick={() => {
                  onOpen()
                  setApprovalMode(MODES.APPROVE)
                }}
                minW="100px"
                colorScheme="blue"
                marginRight={4}
              >
                <Text as="span">Approve</Text>
              </Button>
              <Button
                size="md"
                onClick={() => {
                  onOpen()
                  setApprovalMode(MODES.REJECT)
                }}
                minW="100px"
                colorScheme="red"
              >
                <Text as="span">Reject</Text>
              </Button>
            </>
          )}
        </Flex>
        <ThemedTable
          columns={columns}
          rows={records}
          mt="20px"
          isLoading={isLoading}
          disableNext={!res?.data?.hasNextPage}
          disablePrev={Number(variables.currentPage) + 1 === 1}
          handleNext={handleNext}
          handlePrev={handlePrev}
          setChecks={setChecks}
          allowMultipleChecks
          // @ts-ignore
          ref={tableRef}
          setHasChecks={setHasChecks}
        />
        <Modal
          isOpen={isOpen}
          onClose={() => {
            if (!isSubmitting) {
              onClose()
            }
          }}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent p="0px" minW="500px">
            <ModalHeader>
              <Text as="span" fontWeight="600" fontSize="20px">
                {approvalMode}
              </Text>
            </ModalHeader>
            <ModalBody paddingRight="32px">
              <Text as="span" fontSize="16px" display="block">
                Are you sure to proceed?
              </Text>
            </ModalBody>
            <ModalFooter justifyContent="end">
              <Button
                size="sm"
                onClick={() => {
                  onClose()
                }}
                mr="12px"
                isDisabled={isSubmitting}
                minW="100px"
              >
                <Text as="span">Cancel</Text>
              </Button>
              <Button
                size="sm"
                onClick={onApprovalClick}
                isDisabled={isSubmitting}
                minW="100px"
                colorScheme="blue"
              >
                {isSubmitting ? (
                  <Spinner color="gray.500" size="sm" />
                ) : (
                  <Text as="span">Confirm</Text>
                )}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </DefaultLayout>
    </>
  )
}
export default PendingExhibitors
