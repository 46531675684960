import {
  Box,
  Flex,
  Image,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { FiStar } from 'react-icons/fi'
import { IoMdBoat } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { DateTime as dt } from 'luxon'

import { useAuthentication } from '../../hooks'
import Notifications from './Notifications'
import { Notification } from '../../interfaces'
import { generateNotifMessage } from '../../utils'
import { BASE_URL } from '../../config'
import CompanyImage from './CompanyImage'
import { httpPost } from '../../fetchers'

const Topbar: React.FC = () => {
  const [notifs, setNotifs] = useState<Notification[]>([])
  const { isAuthenticated } = useAuthentication()

  const newNotifs = notifs
    ?.filter(n => Math.abs(dt.fromISO(n.createdAt).diffNow('hours').hours) <= 6)
    .map(n => {
      const diffMonths = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('months').months)
      )
      const diffDays = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('days').days)
      )
      const diffHours = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('hours').hours)
      )
      const diffMinutes = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('minutes').minutes)
      )
      const diffSeconds = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('seconds').seconds)
      )

      if (diffMonths) {
        return {
          ...n,
          diff: `${diffMonths} day${diffMonths > 1 ? 's' : ''} ago`,
        }
      }
      if (diffDays) {
        return {
          ...n,
          diff: `${diffDays} day${diffDays > 1 ? 's' : ''} ago`,
        }
      }
      if (diffHours) {
        return {
          ...n,
          diff: `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`,
        }
      }
      if (diffMinutes) {
        return {
          ...n,
          diff: `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`,
        }
      }
      return {
        ...n,
        diff: `${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`,
      }
    })

  const earlierNotifs = notifs
    ?.filter(n => Math.abs(dt.fromISO(n.createdAt).diffNow('hours').hours) > 6)
    .map(n => {
      const diffMonths = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('months').months)
      )
      const diffDays = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('days').days)
      )
      const diffHours = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('hours').hours)
      )
      const diffMinutes = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('minutes').minutes)
      )
      const diffSeconds = Math.floor(
        Math.abs(dt.fromISO(n.createdAt).diffNow('seconds').seconds)
      )

      if (diffMonths) {
        return {
          ...n,
          diff: `${diffMonths} day${diffMonths > 1 ? 's' : ''} ago`,
        }
      }
      if (diffDays) {
        return {
          ...n,
          diff: `${diffDays} day${diffDays > 1 ? 's' : ''} ago`,
        }
      }
      if (diffHours) {
        return {
          ...n,
          diff: `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`,
        }
      }
      if (diffMinutes) {
        return {
          ...n,
          diff: `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`,
        }
      }
      return {
        ...n,
        diff: `${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`,
      }
    })

  const onRead = async (notifId: string) => {
    await httpPost({
      url: `${BASE_URL}/notifications/read`,
      body: { notifId },
    })
  }

  return (
    <Flex
      height="80px"
      boxShadow="0px 4px 9px rgba(0, 0, 0, 0.05)"
      p="20px"
      justifyContent="flex-end"
      alignItems="center"
      position="relative"
      zIndex="2"
    >
      {isAuthenticated && (
        <>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  as={Button}
                  backgroundColor="transparent"
                  _hover={{
                    backgroundColor: 'gray.100',
                  }}
                  _active={{
                    backgroundColor: 'gray.500',
                  }}
                  borderRadius="40px"
                  size="sm"
                  position="relative"
                >
                  <Notifications
                    isOpen={isOpen}
                    notifs={notifs}
                    setNotifs={setNotifs}
                  />
                </MenuButton>
                <MenuList minW="35ch">
                  {notifs?.length ? (
                    <Box maxH="400px" overflow="auto">
                      {!!newNotifs.length && (
                        <Box>
                          <Text
                            as="span"
                            ml="20px"
                            fontWeight="500"
                            fontSize="sm"
                          >
                            New
                          </Text>

                          <Box mt="2px">
                            {newNotifs.map(n => {
                              const generatedMessage = generateNotifMessage({
                                message: n.message || '',
                                companyName: n.companyName,
                              })

                              const entity = n.message
                                ?.split('~')
                                ?.slice(-1)[0]
                                ?.split('_')
                                .join('-')
                                .toLowerCase()

                              const isExhibitorEntity = entity === 'exhibitor'

                              const pluralEntities = (argEntity: string) =>
                                argEntity.includes('exhibitor-boat-product')
                                  ? 'my-boats-at-the-show'
                                  : 'my-products-services'

                              return (
                                <MenuItem key={n.notifId}>
                                  <Link
                                    to={{
                                      pathname: `/company/${n.companyId}/${
                                        isExhibitorEntity
                                          ? 'my-exhibit-space'
                                          : pluralEntities(entity)
                                      }`,
                                      ...(n.token && {
                                        search: `?t=${n.token}`,
                                      }),
                                      state: {
                                        pending: true,
                                      },
                                    }}
                                    onClick={() => {
                                      onRead(n.notifId)
                                    }}
                                  >
                                    <Flex
                                      justifyItems="center"
                                      alignItems="center"
                                    >
                                      <Text
                                        as="span"
                                        fontSize="2xl"
                                        mr="16px"
                                        ml="6px"
                                      >
                                        <FiStar
                                          fill={
                                            n.clicked ? '#A0AEC0' : '#3300CC'
                                          }
                                          color={
                                            n.clicked ? '#A0AEC0' : '#3300CC'
                                          }
                                        />
                                      </Text>
                                      <Box maxW="35ch">
                                        <Text
                                          as="span"
                                          display="block"
                                          fontSize="xs"
                                        >
                                          {generatedMessage}
                                        </Text>
                                        <Text
                                          mt="4px"
                                          as="span"
                                          display="block"
                                          fontSize="xs"
                                          color="violet"
                                        >
                                          {n.diff}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Link>
                                </MenuItem>
                              )
                            })}
                          </Box>
                        </Box>
                      )}

                      {!!earlierNotifs.length && (
                        <Box>
                          <Text
                            as="span"
                            ml="20px"
                            fontWeight="500"
                            fontSize="sm"
                          >
                            Earlier
                          </Text>
                          <Box mt="2px">
                            {earlierNotifs.map(n => {
                              const generatedMessage = generateNotifMessage({
                                message: n.message || '',
                                companyName: n.companyName,
                              })

                              const entity = n.message
                                ?.split('~')
                                ?.slice(-1)[0]
                                ?.split('_')
                                .join('-')
                                .toLowerCase()

                              const isExhibitorEntity = entity === 'exhibitor'

                              const pluralEntities = (argEntity: string) =>
                                argEntity.includes('exhibitor-boat-product')
                                  ? 'my-boats-at-the-show'
                                  : 'my-products-services'

                              return (
                                <MenuItem key={n.notifId}>
                                  <Link
                                    to={{
                                      pathname: `/company/${n.companyId}/${
                                        isExhibitorEntity
                                          ? 'my-exhibit-space'
                                          : pluralEntities(entity)
                                      }`,
                                      ...(n.token && {
                                        search: `?t=${n.token}`,
                                      }),
                                      state: {
                                        pending: true,
                                      },
                                    }}
                                    onClick={() => {
                                      onRead(n.notifId)
                                    }}
                                  >
                                    <Flex
                                      justifyItems="center"
                                      alignItems="center"
                                    >
                                      <Text
                                        as="span"
                                        fontSize="2xl"
                                        mr="16px"
                                        ml="6px"
                                      >
                                        <FiStar
                                          fill={
                                            n.clicked ? '#A0AEC0' : '#3300CC'
                                          }
                                          color={
                                            n.clicked ? '#A0AEC0' : '#3300CC'
                                          }
                                        />
                                      </Text>
                                      <Box maxW="35ch">
                                        <Text
                                          as="span"
                                          display="block"
                                          fontSize="xs"
                                        >
                                          {generatedMessage}
                                        </Text>
                                        <Text
                                          mt="4px"
                                          as="span"
                                          display="block"
                                          fontSize="xs"
                                          color="violet"
                                        >
                                          {n.diff}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Link>
                                </MenuItem>
                              )
                            })}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box minW="35ch" textAlign="center">
                      <Flex mt="20px">
                        <Text
                          as="span"
                          display="block"
                          mx="auto"
                          fontSize="4xl"
                        >
                          <IoMdBoat />
                        </Text>
                      </Flex>
                      <Text
                        as="span"
                        display="block"
                        ml="10px"
                        mb="20px"
                        fontSize="sm"
                        mt="12px"
                        color="gray.700"
                      >
                        There are no notifications.
                      </Text>
                    </Box>
                  )}
                </MenuList>
              </>
            )}
          </Menu>
          <Menu>
            <MenuButton
              as={Button}
              variant="ghost"
              _active={{
                backgroundColor: 'transparent',
              }}
              _hover={{
                backgroundColor: 'transparent',
              }}
            >
              <Image src="/assets/png/nmma.png" cursor="pointer" mr="8px" />
            </MenuButton>
          </Menu>
        </>
      )}
      {isAuthenticated === false && <CompanyImage />}
    </Flex>
  )
}

export default Topbar
