import { Box, Text, Flex } from '@chakra-ui/react'
import { ReactComponent as NotFound } from '../assets/svg/not-found.svg'
import { ReactComponent as DiscoverBoatingLogo } from '../assets/svg/discover-boating.svg'

const NotFoundPage: React.FC = () => (
  <>
    <Flex backgroundColor="black" height="80px" pl="24px" pt="24px">
      <DiscoverBoatingLogo />
    </Flex>
    <Flex h="calc(100vh - 80px)">
      <Box maxW="900px" mx="auto" pt="100px">
        <NotFound />
        <Box mt="32px">
          <Text
            as="span"
            fontSize="lg"
            fontWeight="500"
            display="block"
            mt="12px"
            textAlign="center"
          >
            The page you are looking for does not exist.
          </Text>
        </Box>
      </Box>
    </Flex>
  </>
)

export default NotFoundPage
