import axios from 'axios'
import pluralize from 'pluralize'
import * as ULID from 'ulid'

// import { BASE_URL } from '../config'
import { GenerateNotifMessageArgs } from '../interfaces'

export const generateNotifMessage = ({
  companyName,
  message,
}: GenerateNotifMessageArgs) => {
  if (message) {
    const entity = message
      ?.split('~')
      ?.slice(-1)[0]
      ?.split('_')
      .join(' ')
      .toLowerCase()

    const action = message?.split('~')?.[0].toLowerCase()
    const article = pluralize(entity) === entity ? 'The' : 'An'

    return `${article} ${entity} from ${companyName} ${
      article === 'The' ? 'were' : 'was'
    } recently ${action} and currently waiting for an approval.`
  }
  return ``
}

export const uploadPhotoToS3 = async (arg: {
  url: string
  file: File
  defaultValues?: any
  hasImageChanged?: boolean
}) => {
  await axios.put(arg.url, arg.file, {
    headers: {
      'Content-Type': arg.file.type,
    },
  })
}

export const yesOrNo = (arg: string | boolean) => {
  if (typeof arg === 'boolean') {
    return arg ? 'Yes' : 'No'
  }
  return arg === 'true' ? 'Yes' : 'No'
}

export const generateHash = () => ULID.ulid()

export default {}
