import { Flex, Text, FlexProps } from '@chakra-ui/react'
import { ReactComponent as Nothing } from '../../assets/svg/nothing.svg'

const EmptyRecords: React.FC<FlexProps> = ({ ...props }) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    px="49px"
    mt="49px"
    {...props}
  >
    <Flex w="full" background="white" borderRadius="20px" h="480px">
      <Flex direction="column" justifyContent="center" mx="auto" pt="1rem">
        <Nothing />
        <Text
          as="span"
          display="block"
          textAlign="center"
          fontSize="xl"
          color="gray.600"
          fontWeight="500"
          mt="24px"
        >
          No records found.
        </Text>
      </Flex>
    </Flex>
  </Flex>
)

export default EmptyRecords
