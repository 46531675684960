import styled from '@emotion/styled';
import { Flex, Button, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import uuid from 'react-uuid';
import PageSize from './PageSize';

interface PaginationProps extends FlexProps {
  isLoadingData?: boolean;
  disablePrev?: boolean;
  disableNext?: boolean;
  handleNext?: () => void;
  handlePrev?: () => void;
  setQueryPageSize?: (arg: number) => void;
}

const StyledPagination = styled(Flex)`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
  justify-content: flex-start;
  padding: 5px;
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.05);
  left: 245px;
`;

const StyledButton = styled(Button)`
  /* Add additional styles for your buttons */
  margin-right: 10px; /* Add margin to create space between buttons */
`;

const Pagination: React.FC<PaginationProps> = ({
  isLoadingData,
  disablePrev,
  disableNext,
  handlePrev,
  handleNext,
  setQueryPageSize,
}) => (
  <StyledPagination>
    <StyledButton
      fontWeight="normal"
      fontSize="17px"
      size="xs"
      key={uuid()}
      borderRadius="3px"
      backgroundColor={
        isLoadingData || disablePrev ? 'gray.200' : 'transparent'
      }
      {...((isLoadingData || disablePrev) && {
        _hover: {
          backgroundColor: 'gray.300',
        },
      })}
      color="darkGray"
      p="1.25rem"
      isDisabled={isLoadingData || disablePrev}
      onClick={handlePrev}
    >
      <FiChevronLeft color="#989DA9" />
    </StyledButton>
    <StyledButton
      fontWeight="normal"
      fontSize="17px"
      size="xs"
      key={uuid()}
      borderRadius="3px"
      backgroundColor={
        isLoadingData || disableNext ? 'gray.200' : 'transparent'
      }
      {...((isLoadingData || disablePrev) && {
        _hover: {
          backgroundColor: 'gray.300',
        },
      })}
      color="darkGray"
      p="1.25rem"
      isDisabled={isLoadingData || disableNext}
      onClick={handleNext}
    >
      <FiChevronRight color="#989DA9" />
    </StyledButton>
    {!!setQueryPageSize && (
      <PageSize
        isLoadingData={isLoadingData}
        setQueryPageSize={setQueryPageSize}
      />
    )}
  </StyledPagination>
);

export default Pagination;
