import { useAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { companyRecordAtom } from '../atoms'
import { fetcher } from '../fetchers'
import { Company, CompanyTypeEnum, ThemedSelectItem } from '../interfaces'

const useBrands = (options?: any) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [brands, setBrands] = useState<ThemedSelectItem[]>([])
  const [companyRecord] = useAtom(companyRecordAtom)
  const isBrand =
    companyRecord.record?.type === CompanyTypeEnum.BRAND ||
    companyRecord.record?.type === CompanyTypeEnum.BROKER

  const fetchBrands = useCallback(async () => {
    setLoading(true)

    if (isBrand && !options?.isMultiple) {
      setBrands([
        {
          label: companyRecord!.record!.name,
          value: companyRecord!.record!.companyId,
        },
      ])
    } else {
      const res = await fetcher(`/company/list?brands=true`)
      if (res.data) {
        const brandsFromResponse = res.data?.Items?.sort(
          (curr: Company, next: Company) => (curr.name < next.name ? -1 : 1)
        ).map((b: Company) => ({
          label: b.name,
          value: b.companyId,
        }))

        setBrands(brandsFromResponse)
      }
    }
    setLoading(false)
  }, [brands?.length, companyRecord.record?.companyId])

  useEffect(() => {
    fetchBrands()
  }, [fetchBrands])

  return { brands, setBrands, isLoading, isBrand }
}

export default useBrands
