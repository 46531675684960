import { useCallback, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { fetcher } from '../fetchers'
import { Show, ThemedSelectItem } from '../interfaces'

const useShows = () => {
  const [shows, setShows] = useState<ThemedSelectItem[]>([])

  const fetchShows = useCallback(async () => {
    const res = await fetcher(`/show/list`)
    if (res.data) {
      const showsFromResponse = res.data?.Items?.reduce(
        (acc: any, curr: Show) => {
          const t = {
            label: curr?.showName,
            value: curr.showId,
            maps: curr?.maps ?? [],
          }
          return [...acc, t]
        },
        [] as { label: string; value: string }[]
      ).sort((curr: any, next: any) => (curr.label < next.label ? -1 : 1))

      setShows(showsFromResponse)
    }
  }, [shows.length])

  useDeepCompareEffect(() => {
    fetchShows()
  }, [shows])

  return { shows, setShows }
}

export default useShows
