import AsyncSelect from 'react-select/async'
import styled from '@emotion/styled'
import { ThemedSelectItem } from '../../interfaces'

type ThemedAsyncSelectProps = {
  cacheOptions: boolean
  loadOptions: (inputValue: string, callback: Function) => void
  defaultOptions: boolean
  defaultValue?: ThemedSelectItem
  maxWidthOptions: number
  [x: string]: any
}

const StyledAsyncSelect = styled(AsyncSelect)`
  & {
    /* background-color: #111827 !important; */
    cursor: pointer !important;

    input {
      /* color: white !important; */
    }

    > div:first-of-type {
      border-radius: 10px;
      border-color: ${({ hasShadow }) =>
        hasShadow ? 'transparent' : '#bdc6d9'};
      box-shadow: ${({ hasShadow }) =>
        hasShadow ? '0px 4px 5px rgba(0, 0, 0, 0.05)' : 'none'};
      min-height: 50px;
      cursor: pointer !important;
      > div:first-of-type {
        /* background-color: #111827; */
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        cursor: pointer !important;
        div {
          font-size: 14px;
          color: ${({ color }) => color ?? 'black'};
        }
      }
      > div:nth-of-type(2) {
        > span {
          display: none;
        }
      }
    }
  }
`

type ColourStyleArgs = {
  marginTopAtValueOf?: string
  marginBottomAtValueOf?: string
  maxWidthOptions?: number
  selectedColor?: string
}

const colourStyles = ({
  marginTopAtValueOf,
  marginBottomAtValueOf,
  maxWidthOptions,
  selectedColor,
}: ColourStyleArgs) => ({
  option: (styles: any, { data, isSelected, isFocused, isDisabled }: any) => {
    const defaultColor = isFocused ? '#F7F8FB' : 'white'

    return {
      ...styles,
      backgroundColor: isSelected ? '#F7F8FB' : defaultColor,
      color: isSelected ? '#3300CC' : selectedColor,
      fontSize: '14px',
      borderRadius: '10px',
      maxWidth: maxWidthOptions,
      marginLeft: '6px',
      padding: '11px',
      marginTop: marginTopAtValueOf === data.value ? '4px' : '2px',
      marginBottom: marginBottomAtValueOf === data.value ? '4px' : '4px',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#F7F8FB' : '#e7e8e9'),
      },
    }
  },
})

const ThemedAsyncSelect: React.FC<ThemedAsyncSelectProps> = ({
  defaultOptions,
  loadOptions,
  cacheOptions,
  maxWidthOptions,
  defaultValue,
  ...rest
}) => (
  <StyledAsyncSelect
    isClearable
    styles={colourStyles({
      maxWidthOptions,
    })}
    cacheOptions={cacheOptions}
    loadOptions={loadOptions}
    defaultOptions={defaultOptions}
    defaultValue={defaultValue}
    noOptionsMessage={() => 'Search for an event...'}
    {...rest}
  />
)

export default ThemedAsyncSelect
