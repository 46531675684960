import { useAtom } from 'jotai'
import { useCallback } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { companyRecordAtom, boatProductsAtom } from '../atoms'
import { fetcher } from '../fetchers'
import { BoatProduct, CompanyTypeEnum, ThemedSelectItem } from '../interfaces'

const useBoatProducts = (brandIds?: ThemedSelectItem[], options?: any) => {
  const [boatProducts, setBoatProducts] = useAtom(boatProductsAtom)
  const [companyRecord] = useAtom(companyRecordAtom)

  const fetchBoatProducts = useCallback(async () => {
    if (brandIds?.length) {
      const api =
        (companyRecord.record?.type !== CompanyTypeEnum.BRAND &&
          brandIds?.length) ||
        options.isMultipleCompanies
          ? `/boat-product/list?companyIds=${brandIds.map((b: any) =>
              encodeURIComponent(b.value)
            )}`
          : `/company/${companyRecord.record?.companyId}/boat-products`

      const res = await fetcher(api)

      if (res.data) {
        const boatProductsFromResponse = res.data?.Items?.reduce(
          (acc: any, curr: BoatProduct) => {
            const t = {
              label: curr.productName,
              value: curr.boatProductId,
              isPublished: curr.published,
              companyId: curr.companyId,
              currentEBPs: curr.currentEBPs,
            }

            return [...acc, t]
          },
          [] as { label: string; value: string }[]
        )

        setBoatProducts(() => {
          const boats = boatProductsFromResponse
            .sort((curr: any, next: any) =>
              curr?.label < next?.label ? -1 : 1
            )
            .filter((boat: any) => {
              if (options?.isPublished) {
                return boat.isPublished === true
              }

              return boat
            })

          return boats
        })
      }
    }
  }, [boatProducts?.length, companyRecord?.record?.companyId, brandIds])

  useDeepCompareEffect(() => {
    fetchBoatProducts()
  }, [boatProducts, companyRecord.record?.companyId, brandIds])

  return { boatProducts, setBoatProducts }
}

export default useBoatProducts
