import { Text } from '@chakra-ui/react'
import { StatusEnum } from '../../interfaces'

type StatusProps = {
  name: StatusEnum
}

const colors = {
  [StatusEnum.ACTIVE]: '#00CB00',
  [StatusEnum.APPROVED]: '#00CB00',
  [StatusEnum.CANCELLED]: '#FF4144',
  [StatusEnum.CLOSED]: '#FF4144',
  [StatusEnum.DECLINED]: '#FF4144',
  [StatusEnum.NONACTIVE]: '#FF4144',
  [StatusEnum.REJECTED]: '#FF4144',
  [StatusEnum.PENDING]: '#FDB713',
  [StatusEnum.PENDING_EBC]: '#FDB713',
  [StatusEnum.APPROVED_EBC]: '#00CB00',
}

const Status: React.FC<StatusProps> = ({ name }) => (
  <Text
    as="span"
    display="block"
    py="4px"
    backgroundColor={colors[name]}
    fontSize="14px"
    color="white"
    borderRadius="25px"
    w="140px !important"
    textAlign="center"
  >
    {name}
  </Text>
)

export default Status
