import { Box, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'
// import { startCase } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { companyRecordAtom } from '../../atoms'

import { companyLinks } from '../../config/links'
import { useAuthentication } from '../../hooks'

const CompanySidebar: React.FC = () => {
  const location = useLocation()
  const { push } = useHistory()
  const split = location.pathname.split('/')
  // const isValidName = true.valueOf
  const name = split[2]

  const [companyRecord] = useAtom(companyRecordAtom)
  const { isAuthenticated } = useAuthentication()

  return (
    <Box
      minH="100%"
      maxH="100%"
      w="200px"
      backgroundColor="white"
      flex="none"
      pt="23px"
    >
      <Box as="ul">
        <Box as="li" listStyleType="none">
          <Text
            as="span"
            color="darkGray"
            fontSize="16px"
            pl="23px"
            display="block"
            maxW="17ch"
            cursor="pointer"
            onClick={() => {
              push(
                `/company/${name}${
                  companyRecord.record?.token
                    ? `?t=${companyRecord.record.token}`
                    : ''
                }`
              )
            }}
            {...(split.length === 3 && { color: 'violet' })}
          >
            {companyRecord.record?.name}
          </Text>
        </Box>
        {companyLinks
          .filter(c =>
            c?.companyTypes
              ? c?.companyTypes.find(t => t === companyRecord.record?.type)
              : true
          )
          .map(c => {
            const isActive = split.length === 4 && `/${split[3]}` === c.url

            const isDisabled = c?.isDisabled && !isAuthenticated
            const isHidden = c?.isHidden && !isAuthenticated

            return isHidden ? (
              <> </>
            ) : (
              <Box
                as="li"
                listStyleType="none"
                key={c.label}
                cursor={`${isDisabled ? 'not-allowed' : 'pointer'}`}
                opacity={`${isDisabled ? 0.5 : 1}`}
                onClick={() => {
                  if (!isDisabled) {
                    push(
                      `/company/${name}${c.url}${
                        companyRecord.record?.token
                          ? `?t=${companyRecord.record.token}`
                          : ''
                      }`
                    )
                  }
                }}
              >
                <Text
                  as="span"
                  color="darkGray"
                  fontSize="16px"
                  pl="23px"
                  display="block"
                  maxW="17ch"
                  mt="48px"
                  {...(isActive && { color: 'violet' })}
                >
                  {c.label}
                </Text>
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}

export default CompanySidebar
