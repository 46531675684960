import React from 'react'
import { Select } from '@chakra-ui/react'

interface PageSizeProps {
  isLoadingData?: boolean
  setQueryPageSize?: (size: number) => void
}

const PageSize: React.FC<PageSizeProps> = ({
  isLoadingData,
  setQueryPageSize,
}) => {
  const pageSizes = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ]

  return (
    <Select
      ml="1rem"
      boxShadow="0px 4px 5px rgba(0, 0, 0, 0.05)"
      borderRadius="10px"
      alignItems="center"
      justifyContent="center"
      maxW="max-content"
      backgroundColor={isLoadingData ? 'gray.300' : 'transparent'}
      isDisabled={isLoadingData}
      onChange={e => {
        setQueryPageSize?.(+e.target.value)
      }}
    >
      {pageSizes.map(ps => (
        <option key={ps.value} value={ps.value}>
          {ps.value}
        </option>
      ))}
    </Select>
  )
}

export default PageSize
