import {
  Grid,
  Flex,
  Box,
  Image,
  Text,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { IoMdBoat } from 'react-icons/io'
import { FiLock, FiUser } from 'react-icons/fi'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import axios from 'axios'

import { ThemedButton } from '../components/shared'
import { useAuthentication } from '../hooks'

import { BASE_URL } from '../config'

interface LoginFormData {
  username: string
  password: string
}

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false)
  const handleShowClick = () => setShowPassword(!showPassword)
  const { register, handleSubmit, watch } = useForm<LoginFormData>()
  const history = useHistory()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const { setAccessToken, setUser } = useAuthentication()

  const onSubmit = async ({ username, password }: LoginFormData) => {
    try {
      setError('')
      setLoading(true)

      const response = await axios.post(`${BASE_URL}/user/login`, {
        username,
        password,
      })

      if (response.status === 200) {
        const { user } = response.data
        const { accessToken } = response.data

        setAccessToken(accessToken)
        setUser(JSON.stringify(user))
        setLoading(false)
        history.push('/')
      }
    } catch (err) {
      const errResponse = (err as any)?.response

      setError(errResponse?.data.message)
      setLoading(false)
    }
  }

  return (
    <Grid templateColumns="repeat(12, 1fr)" minH="100vh">
      <Flex gridColumn="1/6" justifyContent="center" alignItems="center">
        <Box minW="sm">
          <Box fontSize="6xl" mb="24px">
            <IoMdBoat />
          </Box>
          <Box mb="48px">
            <Text fontSize="2xl" as="span" display="block" fontWeight="600">
              Sign In
            </Text>
            <Text as="span" display="block" color="gray.600" fontSize="sm">
              NMMA Discover Boating Portal
            </Text>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl mb="8px">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FiUser />
                </InputLeftElement>
                <Input
                  variant="flushed"
                  type="text"
                  placeholder="Username"
                  fontSize="sm"
                  {...register('username')}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FiLock />
                </InputLeftElement>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  variant="flushed"
                  fontSize="sm"
                  {...register('password')}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Box
              h="30px"
              mt="20px"
              fontSize="xs"
              color="red.400"
              fontWeight="500"
            >
              {error}
            </Box>
            <ThemedButton
              type="submit"
              variant="solid"
              color="white"
              width="full"
              isLoading={loading}
              isDisabled={loading || !watch('username') || !watch('password')}
            >
              Login
            </ThemedButton>
          </form>
        </Box>
      </Flex>
      <Box gridColumn="6/13">
        <Image src="/assets/jpg/boat.jpg" minH="100vh" />
      </Box>
    </Grid>
  )
}

export default Login
