import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Grid,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useDeepCompareEffect from 'use-deep-compare-effect'
import uuid from 'react-uuid'

import { ThemedSelectItem } from '../../../interfaces'
import {
  ThemedButton,
  ThemedDragDrop,
  ThemedFormErrorMessage,
  ThemedInput,
  ThemedLabel,
  ThemedModalTrashButton,
  ThemedSelect,
  ThemedTextarea,
} from '../../shared'
import { httpPost } from '../../../fetchers'
import { BASE_URL } from '../../../config'
import { useAuthentication, useCategories } from '../../../hooks'
import { uploadPhotoToS3 } from '../../../utils'

type CreateProductsServicesFormProps = {
  isOpen: boolean
  onClose: () => void
  mutate?: () => Promise<AxiosResponse<any> | undefined>
  companyId?: string | undefined
  defaultValues?: Record<string, any>
  isAllowedToUpdate?: boolean
}

interface PSFormData {
  companyId: string
  brandName: string
  category: ThemedSelectItem
  description: string
  productName: string
  subCategory: ThemedSelectItem | undefined
  images: File[]
}

const productServiceSchema = yup.object().shape({
  brandName: yup.string(),
  description: yup.string().required('Required.'),
  productName: yup.string().required('Required.'),
  images: yup.array().test({
    message: 'Please provide at least one image to upload.',
    test: arr => {
      if (arr?.length) {
        return arr?.length > 0
      }
      return false
    },
  }),
})

const CreateProductsServicesForm: React.FC<CreateProductsServicesFormProps> = ({
  isOpen,
  onClose,
  companyId,
  mutate,
  defaultValues = {},
}) => {
  const toast = useToast()
  const [key, setKey] = useState<string>(uuid())
  const [subCategoryKey, setSubcategoryKey] = useState<string>(uuid())
  const { categories, subCategories } = useCategories({ includeBoats: false })
  const { isAuthenticated } = useAuthentication()

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<PSFormData>({
    defaultValues: {
      images: [],
      category: categories[0],
    },
    resolver: yupResolver(productServiceSchema),
  })
  const [hasImageChanged, setImageChanged] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const defKeys = Object.keys(defaultValues)
  const onSubmit = async (data: PSFormData) => {
    const forUpdate = defKeys.length
    const { images, subCategory, category, ...rest } = data

    try {
      const image = images[0]

      const fileValues = {
        fileName: image.name,
        fileType: image.type,
      }

      const presignedRes = await httpPost({
        url: `${BASE_URL}/getPresignedUrl`,
        body: fileValues,
      })

      if (presignedRes.data?.url) {
        const isNewFile =
          (Object.keys(defaultValues).length && hasImageChanged) ||
          !Object.keys(defaultValues).length

        const values = {
          ...rest,
          fileName: isNewFile ? image.name : defaultValues?.fileName,
          fileNameHashed: isNewFile
            ? presignedRes.data.fileNameHashed
            : defaultValues?.fileNameHashed,
          companyId,
          category: category.value,
          subCategory: subCategory?.value,
          ...(forUpdate && {
            productServiceId: defaultValues.productServiceId,
          }),
          ...(!isAuthenticated && {
            isPendingExhibitorProductService: true,
          }),
        }

        const res = await httpPost({
          url: `${BASE_URL}/product-service/${forUpdate ? 'update' : 'create'}`,
          body: values,
        })

        if (res?.data) {
          await uploadPhotoToS3({
            url: presignedRes.data?.url,
            file: image,
            defaultValues,
            hasImageChanged,
          })
          mutate?.().then(() => {
            toast({
              title: `Product / Service ${forUpdate ? 'updated' : 'created'}.`,
              description: `The product / service has been ${
                forUpdate ? 'updated' : 'created'
              }.`,
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
          })
          onClose()
        }
      }
    } catch (err) {
      const errResponse = (err as any)?.response

      if (errResponse) {
        const { code } = errResponse.data

        if (code === 'ConditionalCheckFailedException') {
          toast({
            title: `Failed to ${
              forUpdate ? 'update' : 'create'
            } a product / service.`,
            description:
              'There is an existing product / service with the same name.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: `Failed to ${
              forUpdate ? 'update' : 'create'
            } a product / service.`,
            description: errResponse.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      } else {
        console.error(err)
      }
    }
  }

  const onDelete = async () => {
    setIsDeleting(true)
    if (defaultValues?.productServiceId) {
      try {
        const res = await httpPost({
          url: `${BASE_URL}/product-service/delete`,
          body: {
            companyId,
            productServiceId: defaultValues?.productServiceId,
          },
        })

        if (res?.data) {
          await httpPost({
            url: `${BASE_URL}/images/delete`,
            body: {
              fileNameHashed: defaultValues?.fileNameHashed,
            },
          })

          mutate?.().then(() => {
            toast({
              title: 'Product / service has been deleted.',
              description: 'The product / service has been deleted.',
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
          })
          onClose()
        }
      } catch (err) {
        const errResponse = (err as any)?.response

        toast({
          title: 'Failed to delete a product / service.',
          description: errResponse.data.message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
        setIsDeleting(false)
      }
    }
  }

  const imagesField = register('images')
  const watchedImages = watch('images')
  const watchedCategoryValue = watch('category')?.value as string

  useDeepCompareEffect(() => {
    if (watchedImages.length && errors.images?.message) {
      clearErrors('images')
    }
  }, [watchedImages])

  useDeepCompareEffect(() => {
    if (defKeys.length) {
      defKeys.forEach(key => {
        setValue(key as keyof PSFormData, defaultValues[key])
      })
    } else {
      reset()
      setValue('category', categories?.[0])
      setValue('subCategory', subCategories?.[watchedCategoryValue]?.[0])
    }
  }, [defaultValues])

  useDeepCompareEffect(() => {
    if (!watchedCategoryValue) {
      setValue('category', categories[0])
      setKey(uuid())
    }
    setValue('subCategory', subCategories?.[watchedCategoryValue]?.[0])
    setSubcategoryKey(uuid())
  }, [watchedCategoryValue, categories, subCategories])

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="0px" minW="700px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center" pt="50px">
            <Text as="span" fontWeight="600" fontSize="32px">
              {defKeys.length
                ? 'Product-Service Details'
                : 'Add Product-Service'}
            </Text>
          </ModalHeader>
          {!!defKeys.length && (
            <ThemedModalTrashButton
              onClick={onDelete}
              isDeleting={isDeleting}
              entity="product-service"
            />
          )}
          <ModalCloseButton
            color="mediumGray"
            marginTop="15px"
            marginRight="10px"
            isDisabled={isSubmitting || isDeleting}
          />
          <ModalBody paddingLeft="32px" paddingRight="32px">
            <Grid
              mt="20px"
              templateColumns="repeat(2,1fr)"
              autoColumns="max-content"
              autoRows="auto"
              rowGap="18px"
              columnGap="16px"
            >
              <Box>
                <ThemedLabel htmlFor="productName">Product Name</ThemedLabel>
                <ThemedInput id="productName" {...register('productName')} />
                <ThemedFormErrorMessage>
                  {errors.productName?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="brandName">
                  Brand Name (optional)
                </ThemedLabel>
                <ThemedInput id="brandName" {...register('brandName')} />
                <ThemedFormErrorMessage>
                  {errors.brandName?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="images">Upload Image</ThemedLabel>
                <ThemedDragDrop
                  id="images"
                  {...imagesField}
                  ref={imagesField.ref}
                  setValue={setValue}
                  fileNameHashed={defaultValues?.fileNameHashed}
                  fileName={defaultValues.fileName}
                  hasImageChanged={hasImageChanged}
                  setImageChanged={setImageChanged}
                  isSingleUpload
                />
                <ThemedFormErrorMessage>
                  {errors.images?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box gridColumn="1/3">
                <Text as="span" fontWeight="600" fontSize="18px">
                  Options
                </Text>
              </Box>
              <Box>
                <ThemedLabel htmlFor="category">Category</ThemedLabel>
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="category"
                      options={categories || []}
                      isSearchable={false}
                      defaultValue={defaultValues?.category || categories?.[0]}
                      key={key}
                      maxWidthOptions={299}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box>
                <ThemedLabel htmlFor="subCategory">Sub Category</ThemedLabel>
                <Controller
                  control={control}
                  name="subCategory"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="subCategory"
                      options={subCategories?.[watchedCategoryValue] || []}
                      isSearchable={false}
                      defaultValue={
                        defaultValues?.subCategory ||
                        subCategories?.[watchedCategoryValue]?.[0]
                      }
                      key={subCategoryKey}
                      maxWidthOptions={299}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box gridColumn="1 / 3">
                <ThemedLabel htmlFor="description">Description</ThemedLabel>
                <ThemedTextarea id="description" {...register('description')} />
                <ThemedFormErrorMessage>
                  {errors.description?.message}
                </ThemedFormErrorMessage>
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter justifyContent="center" pt="46px" pb="48px">
            <ThemedButton
              isDisabled={isSubmitting || isDeleting}
              type="submit"
              height="55px"
              width="158px"
            >
              {!!defKeys.length && isSubmitting && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              )}
              {!!defKeys.length && !isSubmitting && (
                <Text as="span">Update</Text>
              )}
              {!defKeys.length && isSubmitting && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              )}
              {!defKeys.length && !isSubmitting && (
                <Text as="span">Submit</Text>
              )}
            </ThemedButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateProductsServicesForm
