import { Box, Flex } from '@chakra-ui/react'
import { Sidebar, Topbar } from '../components/shared'

const PublicLayout: React.FC = ({ children }) => (
  <Flex overflow="hidden">
    <Sidebar />
    <Box w="calc(100vw - 245px)" position="relative">
      <Topbar />
      <Box
        minH="calc(100vh - 80px)"
        maxH="calc(100vh - 80px)"
        overflow="hidden auto"
        backgroundColor="lightGray"
      >
        {children}
      </Box>
    </Box>
  </Flex>
)

export default PublicLayout
